import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { CommonService } from "src/app/services/common.service";
import { QuotesStatusServices } from "./quotes-status.service";
import { InventoryNotesComponent } from "../inventory/inventory-notes/inventory-notes.component";
import { EmailModelComponent } from "../email-model/email-model.component";
import { error } from "console";

@Component({
  selector: "app-quotes-status",
  templateUrl: "./quotes-status.component.html",
  styleUrls: ["./quotes-status.component.sass"],
})
export class QuotesStatusComponent implements OnInit {
  // bsModalRef: BsModalRef;
  // isToggleChecked;
  search = "";
  routeParams = {
    followed_up: {
      title: "To Be Followed-Up",
    },
    followed_completed: {
      title: "Follow-Up Complete",
    },
    call_back: {
      title: "Assesor to Call Back",
    },
    ready_yet: {
      title: "Client Not Ready Yet",
    },
    declined: {
      title: "Declined",
    },
    accepted: {
      title: "Accepted",
    },
  };
  auto_reminder = true;
  // mailStatus: any;
  modalRef: BsModalRef;
  status = "";
  daysUntilMailSent: any;
  id: string;
  title = "";
  items = [];
  totalPages: number;
  totalRecords: number = 0;
  currentPageNo: number = 1;
  loader = false;

  constructor(
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private api: QuotesStatusServices,
    private commonService: CommonService,
    private router: Router
  ) {}

  handleSearch(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.search = inputElement.value;
    this.getList();
  }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.status = this.route.snapshot.params.status;
      this.title = this.routeParams[this.status].title;
      this.getList();
      // this.getMasterSwitchData();
    });
  }

  handleGetMasterMail(auto_reminder: any) {
    this.auto_reminder = auto_reminder;
    this.updateMasterEmail();
  }

  handleMasterSwitch(daysUntilMailSent: any) {
    this.daysUntilMailSent = daysUntilMailSent;
    this.updateMasterEmail();
  }

  // getMasterSwitchData() {
  //   try {
  //     this.api.getMasterMail().subscribe(
  //       (result: any) => {
  //         this.auto_reminder = result.data.auto_reminder;
  //         this.daysUntilMailSent = result.data.daysUntilMailSent;
  //         this.id = result.data._id;
  //       },
  //       (error: any) => {
  //         this.commonService.toast(error.error.message, "Error");
  //       }
  //     );
  //   } catch (error) {}
  // }

  updateMasterEmail() {
    if (!this.id) {
      console.error("ID is undefined, cannot update.");
      return;
    }
    this.api
      .updateMasterEmail(this.id, this.auto_reminder, this.daysUntilMailSent)
      .subscribe(
        (response) => {
          this.commonService.toast("Master Switch Updated", "Success");
        },
        (error) => {
          console.error("Error updating master email:", error);
          this.commonService.toast(error.error.message, "Error");
        }
      );
  }

  getList() {
    if (!this.status) {
      return;
    }
    this.loader = true;
    this.api
      .getQuotestList(this.status, this.currentPageNo, this.search)
      .subscribe(
        (result: any) => {
          const response = result.data;
          this.items = response.items;
          this.totalRecords = response.count;
          this.totalPages = response.totalPages;
          this.currentPageNo = response.currentPage;
          this.loader = false;
        },
        (error: any) => {
          this.commonService.toast(error.error.message, "Error");
          this.loader = false;
        }
      );
  }

  handlePageChange({ page }) {
    this.currentPageNo = page;
    this.getList();
  }

  handleUpdateStatus(id: any, event: any) {
    const data = {
      workFlowStatus: event.target.value,
    };
    this.loader = true;
    this.api.updateQuotesStatus(id, data).subscribe(
      (data) => {
        this.getList();
      },
      (errro) => {
        this.commonService.toast(errro.error.message, "Error");
        this.loader = false;
      }
    );
  }

  handleInventoryNotesModel(inventoryId: string) {
    const initialState = {
      inventoryId,
      noteType: "quote",
    };
    this.modalService.show(InventoryNotesComponent, {
      initialState,
      class: "modal-lg",
    });
    this.modalService.onHide.subscribe(() => {
      this.getList();
    });
  }

  handleModel(mailType: any) {
    const initialState = {
      mailType: mailType,
    };
    this.modalRef = this.modalService.show(EmailModelComponent, {
      initialState,
      class: "modal-xl",
    });
  }

  SendManulEmail(
    email: any,
    quote_link: any,
    quote_code: any,
    quote_title: any,
    name: any
  ) {
    this.loader = true;
    this.api
      .sendManualEmail({ email, quote_link, quote_title, quote_code, name })
      .subscribe(
        (response: any) => {
          this.commonService.toast("Mail Send Successfully", "Success");

          this.loader = false;
        },
        (error: any) => {
          this.commonService.toast(error.error.message, "Error");
          this.loader = false;
        }
      );
  }

  handleToggleSwitch(event: Event, id: any) {
    this.api.updateSingleMasterMail(id, event).subscribe(
      (response) => {
        this.getList();
        this.commonService.toast("Status Update Successfully", "Success");
      },
      (error) => {
        console.error("Error updating master email:", error);
        this.commonService.toast(error.error.message, "Error");
      }
    );
  }
}
