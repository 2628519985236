import { BrowserModule, Title } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { ToastrModule, ToastrManager } from "ng6-toastr-notifications";
import { JwtModule } from "@auth0/angular-jwt";
// import { ImageViewerModule } from 'ngx-image-viewer';

import { AppRoutingModule } from "./app-routing.module";
import { AuthModule } from "./modules/auth/auth.module";
import { UtilityModule } from "./modules/utility/utility.module";
import { DashboardModule } from "./modules/dashboard/dashboard.module";
import { UserModule } from "./modules/user/user.module";
import { LayoutsModule } from "./layouts/layouts.module";
import { UserListModule } from "./modules/users/user-list.module";
import { NgxPaginationModule } from "ngx-pagination";
import { AuthInterceptor } from "./services/auth.interceptor";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QualityassuranceModule } from "../app/modules/qualityassurance/qualityassurance.module";
import { InspectionModule } from "./modules/inspection/inspection.module";
import { BookinglinkModule } from "./modules/bookinglink/bookinglink.module";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TagInputModule } from "ngx-chips";

import { AppComponent } from "./app.component";
// import { UnauthLayoutComponent } from './layouts/unauth-layout/unauth-layout.component';

// import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';

/*
import { HeaderComponent } from './layouts/header/header.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { SidebarComponent } from './layouts/sidebar/sidebar.component';
import { BreadcrumbComponent } from './layouts/breadcrumb/breadcrumb.component';
*/
import { AuthGuardService } from "./modules/auth/services/auth-guard.service";
import { PageTitleService } from "./services/page-title.service";
import { BreadcrumbService } from "./services/breadcrumb.service";
import { CommonService } from "./services/common.service";
import { UserInfoService } from "./services/user-info.service";
import { UserListService } from "./modules/users/services/user-list.service";
import { NgxSpinnerModule } from "ngx-spinner";
// import { AttributeListComponent } from './modules/attributes/attribute-list/attribute-list.component';
import { UserAddComponent } from "./modules/users/user-add/user-add.component";
import { TemplatesModule } from "./modules/templates/templates.module";
import { CausesModule } from "./modules/causes/causes.module";
import { JobsModule } from "./modules/jobs/jobs.module";
import { LegendsModule } from "./modules/legends/legends.module";
import { RepairModule } from "./modules/repair/repair.module";
import { QuotesModule } from "./modules/quotes/quotes.module";
import { ToolboxesModule } from "./modules/toolboxes/toolboxes.module";
import { PlumbingModule } from "./modules/plumbing/plumbing.module";
import { AnalyticsModule } from "./modules/analytics/analytics.module";
import { DiscountModule } from "./modules/discount/discount/discount.module";
// import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PdfpersectionModule } from "./modules/pdfpersection/pdfpersection.module";
import { IpadModule } from "./modules/ipad-template/ipad.module";
import { UserAuthService } from "./modules/auth/user-auth.service";
import { AdminAuthService } from "./modules/auth/admin-auth.service";
import { TermsAndServicesComponent } from "./modules/terms-and-services/terms-and-services.component";
import { TermsAndServiceModule } from "./modules/terms-and-services/terms-and-services.module";
import { PaginationModule } from "ngx-bootstrap";
import {
  CommonModule,
  HashLocationStrategy,
  LocationStrategy,
} from "@angular/common";
import { PipesModule } from "src/app/pipes/pipes.module";

import { InventorylistComponent } from "./modules/inventory/inventorylist/inventorylist.component";
import { EditInventoryComponent } from "./modules/inventory/edit-inventory/edit-inventory.component";
import { InventoryNotesComponent } from "./modules/inventory/inventory-notes/inventory-notes.component";
import { InventoryNoteModalComponent } from "./modules/inventory/inventory-note-modal/inventory-note-modal.component";
import { InternalOrderListComponent } from "./modules/inventory/internal-order-list/internal-order-list.component";
import { InternalOrderFormComponent } from "./modules/inventory/internal-order-form/internal-order-form.component";
import { PurchaseOrderFormComponent } from "./modules/inventory/purchase-order-form/purchase-order-form.component";
import { PurchaseOrderListComponent } from "./modules/inventory/purchase-order-list/purchase-order-list.component";
import { InternalOrderModalComponent } from "./modules/inventory/internal-order-modal/internal-order-modal.component";
import { PurchaseOrderModalComponent } from "./modules/inventory/purchase-order-modal/purchase-order-modal.component";
import { PasswordCheckerComponent } from "./modules/inventory/password-checker/password-checker.component";
import { InventoryDocUploaderComponent } from "./modules/inventory/inventory-doc-uploader/inventory-doc-uploader.component";
import { SupportTicketModule } from "./modules/support-ticket/support-ticket.module";
import { EmployeeListComponent } from "./modules/employee/employee-list/employee-list.component";
import { EmployeeFormComponent } from "./modules/employee/employee-form/employee-form.component";
import { AddEmployeeFormComponent } from "./modules/employee/add-employee-form/add-employee-form.component";
import { EmployeeModalComponent } from "./modules/employee/employee-modal/employee-modal.component";
import { TimesheetListComponent } from "./modules/timesheet/timesheet-list/timesheet-list.component";
import { TimesheetFormComponent } from "./modules/timesheet/timesheet-form/timesheet-form.component";
import { TimesheetModalComponent } from "./modules/timesheet/timesheet-modal/timesheet-modal.component";
import { CompanyOverviewComponent } from "./modules/company-overview/company-overview.component";
import { SalesPerformanceComponent } from "./modules/sales-performance/sales-performance.component";
import { InventoryLinksComponent } from "./modules/inventory/inventory-links/inventory-links.component";
import { InventoryLinksModalComponent } from "./modules/inventory/inventory-links-modal/inventory-links-modal.component";
import { PortalComponent } from "./modules/portal/portal.component";
import { AddPortalFormComponent } from "./modules/portal/add-portal/add-portal-form/add-portal-form.component";
import { EditPortalComponent } from "./modules/portal/edit-portal/edit-portal.component";
import { QuotesStatusComponent } from "./modules/quotes-status/quotes-status.component";
import { AdministrativeProcedureComponent } from "./modules/document/administrative-procedure/administrative-procedure.component";
import { AdministrativeFormComponent } from "./modules/document/administrative-form/administrative-form.component";
import { AdministrativeModalComponent } from "./modules/document/administrative-modal/administrative-modal.component";
//import { AdddiscountComponent } from './modules/discount/adddiscount/adddiscount.component'
import { VehicelListComponent } from "./modules/vehicel-management/vehicel-list/vehicel-list.component";
import { VehicleAddPageComponent } from "./modules/vehicel-management/vehicle-add-page/vehicle-add-page.component";
import { VehicelAddEditComponent } from "./modules/vehicel-management/vehicel-add-edit/vehicel-add-edit.component";
import { AnnualLeaveComponent } from "./modules/human-resource/annual-leave/annual-leave.component";
import { IncidentComponent } from "./modules/human-resource/incident/incident.component";
import { IncidentPopComponent } from "./modules/human-resource/incident-pop/incident-pop.component";
import { SwmsComponent } from "./modules/human-resource/swms/swms.component";
import { EmailModelComponent } from "./modules/email-model/email-model.component";
// import { EmailModelComponent } from './email-model/email-model.component';
import { UiSwitchModule } from "ngx-toggle-switch";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function tokenGetter() {
  return localStorage.getItem("currentSession");
}

@NgModule({
  declarations: [
    AppComponent,
    UserAddComponent,
    InventorylistComponent,
    EditInventoryComponent,
    InventoryNotesComponent,
    InventoryNoteModalComponent,
    InternalOrderListComponent,
    InternalOrderFormComponent,
    PurchaseOrderFormComponent,
    PurchaseOrderListComponent,
    InternalOrderModalComponent,
    PurchaseOrderModalComponent,
    PasswordCheckerComponent,
    InventoryDocUploaderComponent,
    EmployeeListComponent,
    EmployeeFormComponent,
    AddEmployeeFormComponent,
    EmployeeModalComponent,
    TimesheetListComponent,
    TimesheetFormComponent,
    TimesheetModalComponent,
    CompanyOverviewComponent,
    SalesPerformanceComponent,
    InventoryLinksComponent,
    InventoryLinksModalComponent,
    PortalComponent,
    AddPortalFormComponent,
    EditPortalComponent,
    QuotesStatusComponent,
    AdministrativeProcedureComponent,
    AdministrativeFormComponent,
    AdministrativeModalComponent,
    VehicelListComponent,
    VehicleAddPageComponent,
    VehicelAddEditComponent,
    AnnualLeaveComponent,
    IncidentComponent,
    IncidentPopComponent,
    SwmsComponent,
    EmailModelComponent,
    // AdddiscountComponent,
  ],
  imports: [
    PaginationModule.forRoot(),
    BrowserModule,
    PipesModule,
    //PdfViewerModule,
    QualityassuranceModule,
    BrowserAnimationsModule,
    RouterModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    BookinglinkModule,
    InspectionModule,
    AnalyticsModule,
    NgxSpinnerModule,
    JwtModule.forRoot({
      config: {
        tokenGetter,
      },
    }),
    TagInputModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ReactiveFormsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    AppRoutingModule,
    AuthModule,
    DashboardModule,
    UtilityModule,
    LayoutsModule,
    UserModule,
    IpadModule,
    UserListModule,
    SupportTicketModule,
    TemplatesModule,
    NgxPaginationModule,
    CausesModule,
    TermsAndServiceModule,
    PlumbingModule,
    JobsModule,
    PdfpersectionModule,
    LegendsModule,
    RepairModule,
    QuotesModule,
    ToolboxesModule,
    DiscountModule,
    UiSwitchModule,
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    ToastrManager,
    AuthGuardService,
    UserAuthService,
    AdminAuthService,
    CommonService,
    PageTitleService,
    UserInfoService,
    BreadcrumbService,
    UserListService,
    Title,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

//platformBrowserDynamic().bootstrapModule(AppModule);
