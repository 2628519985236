import { Component, OnInit } from "@angular/core";
import { UserService } from "./../../services/user.service";
import { ChangePasswordResponse } from "./../../interfaces/user";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CommonService } from "../../../../services/common.service";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"],
})
export class ChangePasswordComponent implements OnInit {
  token: String;
  submitted = false;
  changePasswordForm: FormGroup;
  loader: Boolean;
  show_confirm_password: Boolean;
  show_password: Boolean;
  show_old_password: Boolean;
  constructor(
    private userService: UserService,
    private formBuilder: FormBuilder,
    private commonService: CommonService
  ) {
    this.token = "";
    this.show_confirm_password = false;
    this.show_password = false;
    this.show_old_password = false;
  }

  ngOnInit() {
    this.loader = false;
    this.changePasswordForm = this.formBuilder.group(
      {
        old_password: ["", [Validators.required]],
        password: [
          "",
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(16),
            Validators.pattern(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\!\@\#\$\%\^\&\*])[A-Za-z\d\!\@\#\$\%\^\&\*]{8,16}$/
            ),
          ],
        ],
        confirm_password: [
          "",
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(16),
            Validators.pattern(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\!\@\#\$\%\^\&\*])[A-Za-z\d\!\@\#\$\%\^\&\*]{8,16}$/
            ),
          ],
        ],
      },
      { validator: this.passwordValidator }
    );
  }

  get f() {
    return this.changePasswordForm.controls;
  }

  passwordValidator(group) {
    const password = group.get("password");
    const confirm_password = group.get("confirm_password");

    if (
      password.value !== "" &&
      confirm_password.value !== "" &&
      password.value !== confirm_password.value
    ) {
      return confirm_password.setErrors({ notEquivalent: true });
    } else {
    }
  }

  changePassword() {
    this.submitted = true;
    this.loader = true;
    var userId = localStorage.getItem("userId");
    userId = JSON.parse(userId);
    if (!this.changePasswordForm.invalid) {
      var obj = {
        oldPassword: this.changePasswordForm.controls.old_password.value,
        newPassword: this.changePasswordForm.controls.password.value,
        cPassword: this.changePasswordForm.controls.password.value,
        _id: userId.toString(),
      };
      this.userService.changePassword(obj).subscribe(
        (response: ChangePasswordResponse) => {
          this.loader = false;
          if (response.success === true) {
            this.commonService.toast(
              `Password changed successfully.`,
              "Success"
            );
          } else {
            if (response.success === false) {
              this.commonService.toast(response.message, "Error");
            } else {
              this.commonService.toast(response.message.toString(), "Error");
            }
          }
        },
        (error) => {
          this.loader = false;
          this.commonService.toast(error.error.message, "Error");
        }
      );
    } else {
      this.loader = false;
    }
  }

  toggleOldPasswordView() {
    if (this.show_old_password === true) {
      this.show_old_password = false;
    } else {
      this.show_old_password = true;
    }
  }

  toggleConfirmView() {
    if (this.show_confirm_password === true) {
      this.show_confirm_password = false;
    } else {
      this.show_confirm_password = true;
    }
  }

  togglePasswordView() {
    if (this.show_password === true) {
      this.show_password = false;
    } else {
      this.show_password = true;
    }
  }
}

// import { Component, OnInit } from "@angular/core";
// import { UserService } from "./../../services/user.service";
// import { ChangePasswordResponse } from "./../../interfaces/user";
// import { FormBuilder, FormGroup, Validators } from "@angular/forms";
// import { CommonService } from "../../../../services/common.service";
// import { MatDialog } from "@angular/material/dialog";
// import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
// import { OtpSentModalComponent } from "../otp-sent-modal/otp-sent-modal.component";

// @Component({
//   selector: "app-change-password",
//   templateUrl: "./change-password.component.html",
//   styleUrls: ["./change-password.component.scss"],
// })
// export class ChangePasswordComponent implements OnInit {
//   modalRef?: BsModalRef;

//   token: String;
//   submitted = false;
//   changePasswordForm: FormGroup;
//   loader: Boolean;
//   show_confirm_password: Boolean;
//   show_password: Boolean;
//   show_old_password: Boolean;
//   otp: any;
//   usersData: any;
//   generateOtpResponse: any;
//   isOtpSent: Boolean;

//   constructor(
//     private modalService: BsModalService,

//     private userService: UserService,
//     private formBuilder: FormBuilder,
//     private commonService: CommonService,
//     private dialog: MatDialog // Inject MatDialog
//   ) {
//     this.token = "";
//     this.show_confirm_password = false;
//     this.show_password = false;
//     this.show_old_password = false;
//     this.otp = false;
//   }

//   ngOnInit() {
//     this.openModal();
//     this.loader = false;

//     this.changePasswordForm = this.formBuilder.group(
//       {
//         old_password: ["", [Validators.required]],
//         password: [
//           "",
//           [
//             Validators.required,
//             Validators.minLength(8),
//             Validators.maxLength(16),
//             Validators.pattern(
//               /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\!\@\#\$\%\^\&\*])[A-Za-z\d\!\@\#\$\%\^\&\*]{8,16}$/
//             ),
//           ],
//         ],
//         confirm_password: [
//           "",
//           [
//             Validators.required,
//             Validators.minLength(8),
//             Validators.maxLength(16),
//             Validators.pattern(
//               /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\!\@\#\$\%\^\&\*])[A-Za-z\d\!\@\#\$\%\^\&\*]{8,16}$/
//             ),
//           ],
//         ],
//         otp: ["", Validators.required], // Ensure OTP is included
//       },
//       { validator: this.passwordValidator }
//     );

//     // Show a popup message immediately after component is rendered
//     this.commonService.toast("Component has been loaded.", "Info");

//     // Generate OTP when the component is initialized
//   }
//   openModal() {
//     // Ensure that modalRef is defined and the modal opens correctly
//     this.modalRef = this.modalService.show(OtpSentModalComponent, {
//       initialState: {
//         message: "OTP has been sent successfully!", // Pass the message
//       },
//     });
//   }

//   get f() {
//     return this.changePasswordForm.controls;
//   }

//   passwordValidator(group) {
//     const password = group.get("password");
//     const confirm_password = group.get("confirm_password");

//     if (
//       password.value !== "" &&
//       confirm_password.value !== "" &&
//       password.value !== confirm_password.value
//     ) {
//       return confirm_password.setErrors({ notEquivalent: true });
//     } else {
//     }
//   }

//   generateOtpOnInit() {
//     console.log("getting generate otp api called ");
//     const userId = localStorage.getItem("userId");
//     const token = localStorage.getItem("currentSession");
//     console.log(token);
//     if (userId) {
//       const payload = { _id: JSON.parse(userId) };

//       this.userService.generateOtp(payload).subscribe(
//         (response) => {
//           this.usersData = response;
//           console.log(
//             "response==================>>>>",
//             response,
//             this.usersData
//           );
//           if (this.usersData.success) {
//             this.isOtpSent = true;
//             console.log(this.isOtpSent, ">>");
//             this.commonService.toast("OTP generated successfully.", "Success");
//           } else {
//             this.isOtpSent = false;
//             this.commonService.toast(this.usersData.message, "Error");
//           }
//         },
//         (error) => {
//           this.commonService.toast(error.error.message, "Error");
//         }
//       );
//     }
//   }

//   changePassword() {
//     console.log("change password called"); // Check if this log appears
//     this.submitted = true;
//     this.loader = true;

//     console.log(this.changePasswordForm, this.changePasswordForm.invalid);
//     if (!this.changePasswordForm.invalid) {
//       console.log("Form is valid, proceeding to API call..."); // Verify if the form is valid
//       var userId = localStorage.getItem("userId");
//       console.log("userId", userId);
//       userId = JSON.parse(userId);
//       const obj = {
//         oldPassword: this.changePasswordForm.controls.old_password.value,
//         newPassword: this.changePasswordForm.controls.password.value,
//         cPassword: this.changePasswordForm.controls.password.value,
//         otp: this.changePasswordForm.controls.otp.value,
//         _id: userId.toString(),
//       };
//       console.log("Request body:", obj); // Check the request body
//       this.userService.changeAdminPassword(obj).subscribe(
//         (response: ChangePasswordResponse) => {
//           console.log("API response", response); // Check API response
//           this.loader = false;
//           if (response.success === true) {
//             this.commonService.toast(
//               "Password changed successfully.",
//               "Success"
//             );
//           } else {
//             this.commonService.toast(response.message, "Error");
//           }
//         },
//         (error) => {
//           console.log("API error", error); // Check for errors
//           this.loader = false;
//           this.commonService.toast(error.error.message, "Error");
//         }
//       );
//     } else {
//       console.log("Form is invalid"); // Check why the form is invalid
//       this.loader = false;
//     }
//   }

//   toggleOldPasswordView() {
//     this.show_old_password = !this.show_old_password;
//   }

//   toggleConfirmView() {
//     this.show_confirm_password = !this.show_confirm_password;
//   }

//   togglePasswordView() {
//     this.show_password = !this.show_password;
//   }
// }
