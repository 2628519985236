import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  OnDestroy,
  TemplateRef,
} from "@angular/core";
import * as moment from "moment";
import { CommonService } from "src/app/services/common.service";
import { InventoryNotesComponent } from "../../inventory/inventory-notes/inventory-notes.component";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { InventoryLinksComponent } from "../../inventory/inventory-links/inventory-links.component";
import { InventoryDocUploaderComponent } from "../../inventory/inventory-doc-uploader/inventory-doc-uploader.component";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MomentumIndicatorService } from "@syncfusion/ej2-angular-charts";
import { Subject, Subscription } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { PasswordCheckerComponent } from "../../inventory/password-checker/password-checker.component";
import { MarketingService } from "../marketing.service";
import { environment } from "../../../../environments/environment";
import { debounceTime, distinctUntilChanged, switchMap } from "rxjs/operators";

@Component({
  selector: "app-ad-control",
  templateUrl: "./ad-control.component.html",
  styleUrls: ["./ad-control.component.sass"],
})
export class AdControlComponent implements OnInit, OnDestroy {
  @ViewChild("confirmdeleteModal") confirmdeleteModal: ElementRef;
  @ViewChild("closebutton") closebutton;
  @ViewChild("closeProfit") closeProfit;
  @ViewChild("confirmdeleteModal") AdControlComponent: ElementRef;

  modalSubscription: Subscription;
  modalRef: BsModalRef;
  formData: FormGroup;

  constructor(
    private api: MarketingService,
    private commonService: CommonService,
    private modalService: BsModalService,
    private _fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute // private modalService: NgbModal
  ) {}
  isAuthorized: any;
  profitformData: FormGroup;

  // -------------------
  items = [];
  totalPages: number;
  totalRecords: number = 0;
  currentPageNo: number = 1;

  marketingData;
  totalSpentSummary;

  teamAndLocation;
  campaignFormId;
  status;
  target;
  dailySpent;

  loader = false;
  loading = true;

  showEditFields = [
    "1 Day",
    "1 Week",
    "1 Month (30 days)",
    "3 Months (90 days)",
    "6 Months (180 days)",
    "1 Year",
  ];
  private initialEditData = {
    target: [
      // {
      //   key: "one_day",
      //   value: "",
      // },
      {
        key: "week",
        value: "",
      },
      {
        key: "month",
        value: "",
      },
      {
        key: "3month",
        value: "",
      },
      {
        key: "6month",
        value: "",
      },
      {
        key: "year",
        value: "",
      },
    ],
  };

  rangeSelectedId: null;
  SelectedId = null;
  overallHealthScore: number = 30;
  overviewItem: any;
  selectedItem: any;
  nodata = false;
  url;

  durationFilter: string = "week";
  currentStartDate = moment().startOf("isoWeek").format("YYYY-MM-DD");
  currentEndDate = moment().format("YYYY-MM-DD");
  previousStateDate = moment(this.currentStartDate)
    .subtract(1, "weeks")
    .format("YYYY-MM-DD");
  previusEndDate = moment(this.previousStateDate)
    .endOf("isoWeek")
    .format("YYYY-MM-DD");
  isProfit = false;

  item = [
    {
      label: "Pas 7 Days",
      value: "week",
    },
    {
      label: "1 Month",
      value: "month",
    },
    {
      label: "3 Month",
      value: "3month",
    },
    {
      label: "6 Month",
      value: "6month",
    },
    {
      label: "1 year",
      value: "year",
    },
  ];

  targetArr = ["week", "month", "3month", "6month", "year"];

  // variables
  spentLowerRange: any;
  spentUpperRange: any;

  p = 1;
  searchText = "";
  query: any = {
    page: 1,
    limit: environment.defaultPageSize,
    userType: 1,
  };

  public getRoute(url, id) {
    const myurl = `${url}/${id}`;
    this.router.navigateByUrl(myurl).then((e) => {
      if (e) {
        // console.log("Navigation is successful!");
      } else {
        console.log("Navigation has failed!");
      }
    });
  }
  private searchTerms = new Subject<string>();

  ngOnInit() {
    this.DeleteIconComponent();
    this.isAuthorized = "false";
    this.initForm();
    this.handleUserAuthCheck("Marketing/ad-control-list", "Ad Control");

    this.getRange();

    this.searchTerms
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((value) => {
        this.pageChanged({ page: 1 });
        this.query.search = value;
        this.getMarketingData();
      });

    this.getMarketingData();

    this.formData = new FormGroup({
      spentLowerRange: new FormControl(""), // Initial value can be fetched from API
      spentUpperRange: new FormControl(""), // Same here
    });

    // this.formData = this._fb.group({
    //   spentLowerRange: [
    //     "",
    //     [Validators.required, Validators.pattern("^[0-9]*$")],
    //   ],
    //   spentUpperRange: [
    //     "",
    //     [Validators.required, Validators.pattern("^[0-9]*$")],
    //   ],
    // });
    this.formData = this._fb.group({
      spentLowerRange: [
        "",
        [
          Validators.required,
          Validators.min(0),
          Validators.pattern(/^[1-9]\d*$/),
        ],
      ], // Spent lower range should be a positive integer greater than 0
      spentUpperRange: [
        "",
        [
          Validators.required,
          Validators.min(0),
          Validators.pattern(/^[1-9]\d*$/),
        ],
      ], // Spent upper range should be a positive integer greater than 0
    });
  }

  pageChanged(event: any): void {
    this.p = event.page;
    this.query.page = this.p;
    this.getMarketingData();
  }
  // ---------------------------------------------

  confirm() {
    this.deleteMethod(this.selectedItem);
    // this.message = 'Confirmed!';
    this.modalRef.hide();
  }

  decline() {
    // this.message = 'Declined!';
    this.modalRef.hide();
  }
  deleteMethod(user) {
    console.log(user._id);
    this.api.deleteAd(user._id).subscribe(
      (res) => {
        console.log("deleted", res);
        this.loader = true;
        this.getMarketingData();
      },
      (err) => {
        console.log(err);
      }
    );
  }

  openModal(template: TemplateRef<any>, item) {
    this.selectedItem = item;
    this.modalRef = this.modalService.show(template, { class: "modal-sm" });
  }
  handlePageChange({ page }) {
    console.log(page, "==page");
    this.currentPageNo = page;
    this.getMarketingData();
  }

  getMarketingData() {
    this.api
      .getDashboardData(
        this.currentStartDate,
        this.currentEndDate,
        this.previousStateDate,
        this.previusEndDate,
        this.durationFilter,
        this.currentPageNo
      )
      .subscribe(
        (result: any) => {
          this.nodata = false;

          const response = result.data.result[0];
          // const response = result.data;
          console.log("getDashboardData result --------------", response);

          // Assuming individualRecords is the data you want to display
          this.overviewItem = response.preservedFields;
          this.totalSpentSummary = response.totalSpentSummary;

          this.items = response; // Assuming 'items' contains your list of data
          this.totalRecords = result.data.count;
          this.totalPages = result.data.totalPages;
          this.currentPageNo = result.data.currentPage;
          this.loading = false;
          console.log("$$$$$$$", this.overviewItem);
          console.log("$$$$$$$_", this.totalSpentSummary);

          console.log(
            "pagination data",
            this.items,
            this.totalRecords,
            this.totalPages,
            this.currentPageNo
          );
        },
        (error: any) => {
          this.nodata = true;

          this.loading = false;
          this.commonService.toast(error.error.message, "Error");
        }
      );
  }
  formatCurrency(amount: number): string {
    return `$${amount.toLocaleString()}`;
  }
  getRange() {
    this.api.getSpendRange({}).subscribe((result: any) => {
      const response = result.data;
      // Set the initial values for the form controls
      this.formData.setValue({
        spentLowerRange: response.spentLowerRange,
        spentUpperRange: response.spentUpperRange,
      });
      this.rangeSelectedId = response._id;

      this.spentLowerRange = this.formData.get("spentLowerRange").value;
      this.spentUpperRange = this.formData.get("spentUpperRange").value;
    });
  }

  // onSpentRangeChange() {
  //   const data = this.formData.value;

  //   this.updateSpendRange(data);
  // }

  // updateSpendRange(data: any) {
  //   this.loader = true;

  //   if (data.spentLowerRange > data.spentUpperRange) {
  //     this.commonService.toast(
  //       "Spent lower range must be less than the spent upper range.",
  //       "Error"
  //     );
  //     this.loader = false;
  //     this.getRange();
  //     return;
  //   }
  //   if (data.spentLowerRange < data.spentUpperRange) {
  //     this.api.updateSpendRange(data, this.rangeSelectedId).subscribe(
  //       (result: any) => {
  //         console.log(data, "data-------------");
  //         console.log("Update result:", result);
  //         this.commonService.toast(result.message, "Success");
  //         this.closeModal();
  //         this.loader = false;
  //         this.router.navigate(["Marketing/ad-control-list"]);
  //         this.getRange();
  //       },
  //       (error: any) => {
  //         this.commonService.toast(error.error.message, "Error");
  //         this.loader = false;
  //       }
  //     );
  //   }
  // }

  onSpentRangeChange() {
    const spentLowerRange = this.formData.get("spentLowerRange").value;
    const spentUpperRange = this.formData.get("spentUpperRange").value;

    // Validation check: If the lower range is greater than upper range
    if (spentLowerRange > spentUpperRange) {
      alert("Spent lower range must be less than spent upper range.");
      return;
    }

    if (spentLowerRange <= 0 || spentUpperRange <= 0) {
      alert("Both values should be greater than 0.");
      return;
    }

    this.updateSpendRange({
      spentLowerRange,
      spentUpperRange,
    });
  }

  updateSpendRange(data: any) {
    this.loader = true;

    if (data.spentLowerRange > data.spentUpperRange) {
      this.loader = false;
      alert("Spent lower range must be less than the spent upper range.");
      return;
    }

    // If validation passes, proceed with API call to update the range
    this.api.updateSpendRange(data, this.rangeSelectedId).subscribe(
      (result: any) => {
        console.log("Update result:", result);
        this.commonService.toast(result.message, "Success");
        this.closeModal();
        this.loader = false;
        this.router.navigate(["Marketing/ad-control-list"]);
        this.getRange();
      },
      (error: any) => {
        this.commonService.toast(error.error.message, "Error");
        this.loader = false;
      }
    );
  }

  setEditData(array: any) {}
  initForm() {
    this.formData = this._fb.group({
      target: this._fb.array(
        this.initialEditData.target.map((entry) => this.createEntryGroup(entry))
      ),
    });
    this.profitformData = this._fb.group({
      operatingExpenses: this._fb.array(
        this.initialEditData.target.map((entry) => this.createEntryGroup(entry))
      ),
    });
  }

  handleUserAuthCheck(url: any, name: string) {
    this.marketingData = [];

    sessionStorage.setItem("ivu", "false");
    const initialState = {
      component: "ad_control",
      url,
      name,
    };
    this.modalRef = this.modalService.show(PasswordCheckerComponent, {
      initialState,
      class: "modal-lg",
    });

    this.modalSubscription = this.modalService.onHide.subscribe(() => {
      this.isAuthorized = sessionStorage.getItem("ivu");
      if (this.isAuthorized == "true") {
        this.loader = true;
        // this.getoverviewData();
      } else {
        this.router.navigate(["Marketing/ad-control-list"]);
      }
    });
  }

  createEntryGroup(entry: any): FormGroup {
    return this._fb.group({
      key: entry.key,
      value: [entry.value],
    });
  }

  getoverviewData() {
    this.api
      .getDashboardData(
        this.currentStartDate,
        this.currentEndDate,
        this.previousStateDate,
        this.previusEndDate,
        this.durationFilter,
        this.currentPageNo
      )
      .subscribe(
        (result: any) => {
          const response = result.data;
          console.log(response, "!!!!!!!!!!!!!!");
          this.overviewItem = response.preservedFields;
          // this.overallHealthScore = response.overallHealthScore.toFixed();
          this.loading = false;
          // this.updateCSSVariables(this.overallHealthScore);
        },
        (error: any) => {
          this.loading = false;
          this.commonService.toast(error.error.message, "Error");
        }
      );
  }

  updateCSSVariables(data: any) {
    const progressBar = document.querySelector(
      'div[role="progressbar"]'
    ) as HTMLElement;
    if (progressBar) {
      progressBar.style.setProperty("--value", `${data}`);
    }
  }
  getTargetValue(arr: any, fieldKey: any) {
    let temp = arr.filter(({ key, value }) => {
      if (key === this.durationFilter) {
        if (
          fieldKey == "Sales" ||
          fieldKey == "Employee satisfaction" ||
          fieldKey == "Revenue" ||
          fieldKey == "Profit"
        )
          return parseFloat(value).toFixed(2);
        else return value;
      }
    });
    if (
      fieldKey == "Sales" ||
      fieldKey == "Employee satisfaction" ||
      fieldKey == "Revenue" ||
      fieldKey == "Profit"
    )
      return parseFloat(temp[0].value).toFixed(2);
    else return temp[0].value;
  }

  getTrendIcon(current: number, previous: number, key: any): string {
    if (key == "Incomplete jobs" || key == "Complaints")
      return current < previous
        ? "fas fa-arrow-alt-circle-up text-green"
        : "fas fa-arrow-alt-circle-down text-red";
    else
      return current > previous
        ? "fas fa-arrow-alt-circle-up text-green"
        : "fas fa-arrow-alt-circle-down text-red";
  }

  handleSelectSearch(e: any, v: any) {
    this.loading = true;
    this.updateCSSVariables(0);
    if (e === "week") {
      this.durationFilter = "week";
      this.currentStartDate = moment().startOf("isoWeek").format("YYYY-MM-DD");
      this.previousStateDate = moment(this.currentStartDate)
        .subtract(1, "weeks")
        .format("YYYY-MM-DD");
      this.previusEndDate = moment(this.previousStateDate)
        .endOf("isoWeek")
        .format("YYYY-MM-DD");
      this.getMarketingData();
    } else if (e === "month") {
      this.durationFilter = "month";
      this.currentStartDate = moment().startOf("month").format("YYYY-MM-DD");
      this.previousStateDate = moment(this.currentStartDate)
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD");
      this.previusEndDate = moment(this.previousStateDate)
        .endOf("month")
        .format("YYYY-MM-DD");
      this.getMarketingData();
    } else if (e === "3month") {
      this.durationFilter = "3month";
      let currentDate = moment();
      let currentQuarter = Math.ceil(currentDate.month() / 3);
      this.currentStartDate = moment()
        .quarter(currentQuarter)
        .startOf("quarter")
        .format("YYYY-MM-DD"); // got write date
      this.previousStateDate = moment(this.currentStartDate)
        .subtract(3, "month")
        .format("YYYY-MM-DD");
      this.previusEndDate = moment(this.currentStartDate)
        .subtract(1, "day")
        .format("YYYY-MM-DD");
      this.getMarketingData();
    } else if (e === "6month") {
      this.durationFilter = "6month";
      let currentDate = moment();
      let currentSixMonthPeriod = Math.ceil(currentDate.month() / 6);
      // let currentSixMonthPeriod = 2;
      if (currentSixMonthPeriod == 1) {
        let previousYear = moment()
          .subtract(1, "year")
          .startOf("year")
          .format("YYYY-MM-DD");
        this.previousStateDate = moment(previousYear)
          .add(6, "month")
          .startOf("month")
          .format("YYYY-MM-DD");
        this.previusEndDate = moment(this.previousStateDate)
          .add(6, "month")
          .endOf("month")
          .format("YYYY-MM-DD");
        this.currentStartDate = moment(this.previusEndDate)
          .add(1, "day")
          .format("YYYY-MM-DD");
      } else {
        this.previousStateDate = moment().startOf("year").format("YYYY-MM-DD");
        this.previusEndDate = moment(this.previousStateDate)
          .add(5, "month")
          .endOf("month")
          .format("YYYY-MM-DD");
        this.currentStartDate = moment(this.previusEndDate)
          .add(1, "day")
          .format("YYYY-MM-DD");
      }
      this.getMarketingData();
    } else {
      this.durationFilter = "year";
      this.currentStartDate = moment().startOf("year").format("YYYY-MM-DD");
      this.previousStateDate = moment(this.currentStartDate)
        .subtract(1, "year")
        .startOf("month")
        .format("YYYY-MM-DD");
      this.previusEndDate = moment(this.currentStartDate)
        .subtract(1, "year")
        .endOf("year")
        .format("YYYY-MM-DD");
    }
    // this.getoverviewData();
    this.getMarketingData();
  }

  handleInventoryNotesModel(inventoryId: string) {
    const initialState = {
      inventoryId,
      noteType: "ad-control",
    };
    this.modalService.show(InventoryNotesComponent, {
      initialState,
      class: "modal-lg",
    });
    this.modalService.onHide.subscribe(() => {
      this.getoverviewData();
    });
  }

  handleLinksModal(links: any, inventoryId: any) {
    const initialState = {
      items: links,
      inventoryId,
      link: "ad-control",
    };
    this.modalService.show(InventoryLinksComponent, {
      initialState,
      class: "modal-lg",
    });
    this.modalService.onHide.subscribe(() => {
      this.loader = true;
      this.getoverviewData();
    });
  }
  handleDocUpload(companyOverviewId: string, images: string[]) {
    const initialState = {
      companyOverviewId,
      images,
      type: "ad-control",
    };
    this.modalService.show(InventoryDocUploaderComponent, {
      initialState,
      class: "modal-lg",
    });
    this.modalService.onHide.subscribe(() => {
      this.getoverviewData();
    });
  }

  handleEditTarget() {
    const formData = { target: this.formData.value.target };

    this.api.updateSpendRange(formData, this.SelectedId).subscribe(
      (result: any) => {
        console.log("hiiiiiiiiii");
        this.commonService.toast(result.message, "Success");
        this.SelectedId = null;
        this.getoverviewData();
        this.formData.reset();
        this.closebutton.nativeElement.click();
      },
      (error: any) => {
        console.log("biiiiiiiiii");

        this.commonService.toast(error.error.message, "Error");
      }
    );
  }
  closeModal() {
    const modal: any = this.confirmdeleteModal.nativeElement;
    this.modalRef.hide();
  }
  handleEditProfitTarget() {
    const formData = {
      operatingExpenses: this.profitformData.value.operatingExpenses,
    };
    this.api.addOperatingExpense(formData, this.SelectedId).subscribe(
      (result: any) => {
        this.commonService.toast(result.message, "Success");
        this.SelectedId = null;
        this.getoverviewData();
        this.profitformData.reset();
        this.closeProfit.nativeElement.click();
      },
      (error: any) => {
        this.commonService.toast(error.error.message, "Error");
      }
    );
  }
  setSelectedTicketId(id: any, key: any, array: any, isOp: boolean) {
    this.SelectedId = id;
    this.isProfit = isOp;
    for (const item of array) {
      const targetItem = this.initialEditData.target.find(
        (obj) => obj.key === item.key
      );
      if (targetItem) {
        targetItem.value = item.value;
      }
    }
    console.log(this.isProfit, "this.isProfit");
    this.isProfit
      ? (this.profitformData = this._fb.group({
          operatingExpenses: this._fb.array(
            this.initialEditData.target.map((entry) =>
              this.createEntryGroup(entry)
            )
          ),
        }))
      : (this.formData = this._fb.group({
          target: this._fb.array(
            this.initialEditData.target.map((entry) =>
              this.createEntryGroup(entry)
            )
          ),
        }));
  }

  getTargetValueProfit(data: any) {
    let temp = data.filter(({ key, value }) => {
      if (key === this.durationFilter) {
        return parseFloat(value).toFixed(2);
      }
    });
    return parseFloat(temp[0].value).toFixed(2);
  }

  getRsSing(data: any) {
    if (["Revenue", "Profit", "Sales"].includes(data)) return "$";
    else "";
  }
  ngOnDestroy() {
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
    if (this.modalRef) {
      this.modalRef.hide();
      this.modalRef = null;
    }
  }
  DeleteIconComponent() {
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
    if (this.modalRef) {
      this.modalRef.hide();
      this.modalRef = null;
    }
  }
}
