import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BookinglinkComponent } from "./bookinglink/bookinglink.component";
import { SignaturePadModule } from "angular2-signaturepad";
import { ReactiveFormsModule } from "@angular/forms";
import { WarrantylinkComponent } from "./warrantylink/warrantylink.component";
import { ThankyouComponent } from "./thankyou/thankyou.component";
import { BookingListComponent } from "./booking-list/booking-list.component";
import { NgxPaginationModule } from "ngx-pagination";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { FormsModule } from "@angular/forms";
import { PipesModule } from "src/app/pipes/pipes.module";
import { BookingListInprogressComponent } from "./booking-list-inprogress/booking-list-inprogress.component";
import { BookingListClosedComponent } from "./booking-list-closed/booking-list-closed.component";
import { ThankyouModalComponentComponent } from "./thankyou-modal-component/thankyou-modal-component.component";
import { PdfviewComponent } from "./pdfview/pdfview.component";
// import { PdfViewerModule } from 'ng2-pdf-viewer';
//import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
//import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
//import { AngularFileViewerModule } from '@taldor-ltd/angular-file-viewer';
import { MatBadgeModule } from "@angular/material/badge";
import { NotesmodalComponent } from "./notesmodal/notesmodal.component";
import { BookingimageComponent } from "./bookingimage/bookingimage.component";
import { PdppopupviewComponent } from "../pdfpersection/pdppopupview-modal/pdppopupview.component";
import { NewjobComponent } from "./newjob/newjob.component";
import { JobonholdComponent } from "./jobonhold/jobonhold.component";
import { JobcancelComponent } from "./jobcancel/jobcancel.component";
import { TobeinvoiceComponent } from "./tobeinvoice/tobeinvoice.component";
import { AwaitingpaymentComponent } from "./awaitingpayment/awaitingpayment.component";
import { DisputeonholdComponent } from "./disputeonhold/disputeonhold.component";
import { PaidinfullComponent } from "./paidinfull/paidinfull.component";
import { PaginationModule } from "ngx-bootstrap";
import { SearchBookingComponent } from "./search-booking/search-booking.component";
import { CreateNotesComponent } from "./create-notes/create-notes.component";
import { FirstReminderComponent } from "./first-reminder/first-reminder.component";
import { SecondReminderComponent } from "./second-reminder/second-reminder.component";
import { ActionRequiredComponent } from "./action-required/action-required.component";
import { WrittenOffComponent } from "./written-off/written-off.component";
import { LegalActionComponent } from "./legal-action/legal-action.component";
import { ToBeWrittenOffComponent } from "./to-be-written-off/to-be-written-off.component";
import { TermsAndConditionModule } from "./terms-and-condition/terms-and-condition.module";
import { InventoryNoteModalComponent } from "../inventory/inventory-note-modal/inventory-note-modal.component";
import { EditInventoryComponent } from "../inventory/edit-inventory/edit-inventory.component";
import { InventoryNotesComponent } from "../inventory/inventory-notes/inventory-notes.component";
import { InternalOrderModalComponent } from "../inventory/internal-order-modal/internal-order-modal.component";
import { PurchaseOrderModalComponent } from "../inventory/purchase-order-modal/purchase-order-modal.component";
import { PasswordCheckerComponent } from "../inventory/password-checker/password-checker.component";
import { InventoryDocUploaderComponent } from "../inventory/inventory-doc-uploader/inventory-doc-uploader.component";
import { EmployeeFormComponent } from "../employee/employee-form/employee-form.component";
import { EmployeeModalComponent } from "../employee/employee-modal/employee-modal.component";
import { TimesheetModalComponent } from "../timesheet/timesheet-modal/timesheet-modal.component";
import { InventoryLinksComponent } from "../inventory/inventory-links/inventory-links.component";
import { InventoryLinksModalComponent } from "../inventory/inventory-links-modal/inventory-links-modal.component";
import { AddPortalFormComponent } from "../portal/add-portal/add-portal-form/add-portal-form.component";
import { EditPortalComponent } from "../portal/edit-portal/edit-portal.component";
import { AdministrativeFormComponent } from "../document/administrative-form/administrative-form.component";
import { AdministrativeModalComponent } from "../document/administrative-modal/administrative-modal.component";
import { VehicelAddEditComponent } from "../vehicel-management/vehicel-add-edit/vehicel-add-edit.component";
import { IncidentPopComponent } from "../human-resource/incident-pop/incident-pop.component";
import { EmailModelComponent } from "../email-model/email-model.component";

@NgModule({
  declarations: [
    BookinglinkComponent,
    WarrantylinkComponent,
    ThankyouComponent,
    BookingListComponent,
    BookingListInprogressComponent,
    BookingListClosedComponent,
    ThankyouModalComponentComponent,
    PdfviewComponent,
    NotesmodalComponent,
    BookingimageComponent,
    NewjobComponent,
    JobonholdComponent,
    JobcancelComponent,
    TobeinvoiceComponent,
    AwaitingpaymentComponent,
    DisputeonholdComponent,
    PaidinfullComponent,
    SearchBookingComponent,
    CreateNotesComponent,
    FirstReminderComponent,
    SecondReminderComponent,
    ActionRequiredComponent,
    WrittenOffComponent,
    LegalActionComponent,
    ToBeWrittenOffComponent,
  ],
  imports: [
    PaginationModule.forRoot(),
    NgxPaginationModule,
    CommonModule,
    SignaturePadModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    TermsAndConditionModule,
    //NgxExtendedPdfViewerModule,
    FormsModule,
    PipesModule,
    //PdfViewerModule,
    //AngularFileViewerModule,
    MatBadgeModule,
  ],
  entryComponents: [
    NotesmodalComponent,
    BookingimageComponent,
    PdppopupviewComponent,
    EmployeeModalComponent,
    CreateNotesComponent,
    InventoryNotesComponent,
    InventoryNoteModalComponent,
    EditInventoryComponent,
    EmployeeFormComponent,
    InternalOrderModalComponent,
    TimesheetModalComponent,
    PurchaseOrderModalComponent,
    PasswordCheckerComponent,
    InventoryDocUploaderComponent,
    InventoryLinksComponent,
    InventoryLinksModalComponent,
    AddPortalFormComponent,
    EditPortalComponent,
    AdministrativeFormComponent,
    AdministrativeModalComponent,
    VehicelAddEditComponent,
    IncidentPopComponent,
    EmailModelComponent,
  ],
})
export class BookinglinkModule {}
