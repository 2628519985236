import { Injectable } from "@angular/core";
import { RestRequest } from "../../../services/rest-request";
import {
  UpdateProfileRequest,
  ChangePasswordRequest,
} from "./../interfaces/user";
// import * as jwt_decode from 'jwt-decode';
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(
    private restRequest: RestRequest,
    private jwtHelperService: JwtHelperService
  ) {}

  public isAuthenticated(): boolean {
    const token = localStorage.getItem("currentSession");
    const signUpInfo = localStorage.getItem("signUpInfo");
    return (
      !this.jwtHelperService.isTokenExpired(token) &&
      !(
        signUpInfo !== "" &&
        signUpInfo !== null &&
        typeof signUpInfo !== "undefined"
      )
    );
  }

  public isProfileSetup(): boolean {
    const token = localStorage.getItem("currentSession");
    const signUpInfo = localStorage.getItem("signUpInfo");
    return (
      !this.jwtHelperService.isTokenExpired(token) &&
      signUpInfo !== "" &&
      signUpInfo !== null &&
      typeof signUpInfo !== "undefined"
    );
  }

  getProfile(data) {
    return this.restRequest
      .addAuthorization()
      .post("users/getUserProfileData", data);
  }

  updateProfile(data: UpdateProfileRequest) {
    console.log(data);
    // const form_data = new FormData();
    // form_data.append('fname', data.fname ? data.fname.toString() : '');
    // form_data.append('gender', data.gender ? data.gender.toString() : '');
    // form_data.append('image', data.image);
    // form_data.append('password', data.password);

    return this.restRequest.addAuthorization().put("user", data);
  }

  changePassword(data: ChangePasswordRequest) {
    return this.restRequest.post("users/changePassword", data);
  }

  changeAdminPassword(obj: any) {
    return this.restRequest.post("users/changeAdminPassword", obj);
  }
  // changeAdminPassword(obj: any): Observable<ChangePasswordResponse> {
  //   return this.http.post<ChangePasswordResponse>('/api/change-password', obj);
  // }

  generateOtp(data: any) {
    return this.restRequest.addAuthorization().post("users/generateOtp", data);
  }
}
