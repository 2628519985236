import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { InventoryDocUploaderComponent } from "../../inventory/inventory-doc-uploader/inventory-doc-uploader.component";
import { InventoryLinksComponent } from "../../inventory/inventory-links/inventory-links.component";
import { InventoryNotesComponent } from "../../inventory/inventory-notes/inventory-notes.component";
import * as moment from "moment";
import { FormBuilder, FormGroup } from "@angular/forms";
import { PasswordCheckerComponent } from "../../inventory/password-checker/password-checker.component";
import { Subject, Subscription } from "rxjs";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { CommonService } from "src/app/services/common.service";
import { Router } from "@angular/router";
import { MarketingService } from "../marketing.service";
import { debounceTime, distinctUntilChanged, switchMap } from "rxjs/operators";
import { environment } from "../../../../environments/environment";

@Component({
  selector: "app-contact-form",
  templateUrl: "./contact-form.component.html",
  styleUrls: ["./contact-form.component.sass"],
})
export class ContactFormComponent implements OnInit, OnDestroy {
  @ViewChild("confirmdeleteModal") confirmdeleteModal: ElementRef;
  @ViewChild("closebutton") closebutton;
  @ViewChild("closeProfit") closeProfit;
  modalSubscription: Subscription;
  modalRef: BsModalRef;
  constructor(
    private api: MarketingService,
    private commonService: CommonService,
    private modalService: BsModalService,
    private _fb: FormBuilder,
    private router: Router
  ) {}
  isAuthorized: any;
  formData: FormGroup;
  profitformData: FormGroup;
  items = [];
  totalPages: number;
  totalRecords: number = 0;
  currentPageNo: number = 1;

  showEditFields = [
    "1 Day",
    "1 Week",
    "1 Month (30 days)",
    "3 Months (90 days)",
    "6 Months (180 days)",
    "1 Year",
  ];
  private initialEditData = {
    target: [
      // {
      //   key: "one_day",
      //   value: "",
      // },
      {
        key: "week",
        value: "",
      },
      {
        key: "month",
        value: "",
      },
      {
        key: "3month",
        value: "",
      },
      {
        key: "6month",
        value: "",
      },
      {
        key: "one_year",
        value: "",
      },
    ],
  };
  loader = false;
  SelectedId = null;
  overallHealthScore: number = 30;
  loading = true;
  overviewItem: [];
  durationFilter: string = "week";
  currentStartDate = moment().startOf("isoWeek").format("YYYY-MM-DD");
  currentEndDate = moment().format("YYYY-MM-DD");
  previousStateDate = moment(this.currentStartDate)
    .subtract(1, "weeks")
    .format("YYYY-MM-DD");
  previusEndDate = moment(this.previousStateDate)
    .endOf("isoWeek")
    .format("YYYY-MM-DD");
  isProfit = false;
  item = [
    // {
    //   label: "Today",
    //   value: "one_day",
    // },
    {
      // label: "Week",
      label: "Pas 7 Days",

      value: "week",
    },
    {
      label: "1 Month",
      value: "month",
    },
    {
      label: "3 Month",
      value: "3month",
    },
    {
      label: "6 Month",
      value: "6month",
    },
    {
      label: "1 year",
      value: "one_year",
    },
  ];
  targetArr = [
    // "one_day",
    "week",
    "month",
    "3month",
    "6month",
    "one_year",
  ];

  campaignFormUrl;
  formNo;
  formName;
  selectedItem: any;

  p = 1;
  searchText = "";
  query: any = {
    page: 1,
    limit: environment.defaultPageSize,
    userType: 1,
  };
  nodata = false;

  private searchTerms = new Subject<string>();

  ngOnInit() {
    this.DeleteIconComponent();
    this.isAuthorized = "false";
    this.initForm();
    this.handleUserAuthCheck("Marketing/contact-form", "Contact Form");

    this.searchTerms
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((value) => {
        this.pageChanged({ page: 1 });
        this.query.search = value;
        this.getContacts();
      });
    this.getContacts();

    // this.getUserList();
  }
  pageChanged(event: any): void {
    this.p = event.page;
    this.query.page = this.p;
    this.getContacts();
  }
  handlePageChange({ page }) {
    this.currentPageNo = page;
    this.getContacts();
  }
  getContacts() {
    this.api
      .getContactFormDashboardData(
        this.currentStartDate,
        this.currentEndDate,
        this.previousStateDate,
        this.previusEndDate,
        this.durationFilter,
        this.currentPageNo

        // this.query
      )
      .subscribe(
        (result: any) => {
          this.nodata = false;

          // const response = result.data;
          const response = result.data.result;
          console.log(
            "******************************************************************",
            response
          );
          this.overviewItem = response;

          this.items = response; // Assuming 'items' contains your list of data
          this.totalRecords = result.data.count;
          this.totalPages = result.data.totalPages;
          this.currentPageNo = result.data.currentPage;

          console.log("$$$$$$$", this.overviewItem);
          this.loading = false;
        },
        (error: any) => {
          this.nodata = true;

          this.loading = false;
          this.commonService.toast(error.error.message, "Error");
        }
      );
  }

  navigateAddUser() {
    this.router.navigate(["Marketing/contact-form/add"]);
  }
  refreshAllData() {
    this.loading = true;
    console.log("hiiiiiiiiiii");
    this.loading = false;
  }
  public getRoute(url, id) {
    const myurl = `${url}/${id}`;
    this.router.navigateByUrl(myurl).then((e) => {
      if (e) {
        // console.log("Navigation is successful!");
      } else {
        console.log("Navigation has failed!");
      }
    });
  }

  setEditData(array: any) {}
  initForm() {
    this.formData = this._fb.group({
      target: this._fb.array(
        this.initialEditData.target.map((entry) => this.createEntryGroup(entry))
      ),
    });
    this.profitformData = this._fb.group({
      operatingExpenses: this._fb.array(
        this.initialEditData.target.map((entry) => this.createEntryGroup(entry))
      ),
    });
  }

  handleUserAuthCheck(url: any, name: string) {
    sessionStorage.setItem("ivu", "false");
    const initialState = {
      component: "contact_form",
      url,
      name,
    };
    this.modalRef = this.modalService.show(PasswordCheckerComponent, {
      initialState,
      class: "modal-lg",
    });

    this.modalSubscription = this.modalService.onHide.subscribe(() => {
      this.isAuthorized = sessionStorage.getItem("ivu");
      if (this.isAuthorized == "true") {
        this.loader = true;
        // this.getoverviewData();
      } else {
        this.router.navigate(["Marketing/contact-form/list"]);
      }
    });
  }

  createEntryGroup(entry: any): FormGroup {
    return this._fb.group({
      key: entry.key,
      value: [entry.value],
    });
  }

  // deletion

  openModal(template: TemplateRef<any>, item) {
    this.selectedItem = item;
    this.modalRef = this.modalService.show(template, { class: "modal-sm" });
  }
  confirm() {
    this.deleteMethod(this.selectedItem);
    // this.message = 'Confirmed!';
    this.modalRef.hide();
  }
  decline() {
    // this.message = 'Declined!';
    this.modalRef.hide();
  }
  deleteMethod(user) {
    console.log(user._id);
    this.api.deleteContactData(user._id).subscribe(
      (res) => {
        console.log("deleted", res);
        this.loader = true;
        this.commonService.toast(
          "Compaign form deleted successfully",
          "Success"
        );
        this.getContacts();
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getContactFormDashboardData() {
    this.api
      .getContactFormDashboardData(
        this.currentStartDate,
        this.currentEndDate,
        this.previousStateDate,
        this.previusEndDate,
        this.durationFilter,
        this.currentPageNo

        // this.query
      )
      .subscribe(
        (result: any) => {
          // const response = result.data[0];
          const response = result.data.result[0];
          this.overviewItem = response.items;
          this.overallHealthScore = response.overallHealthScore.toFixed();
          // this.items = response.preservedFields; // Assuming 'items' contains your list of data
          // this.totalRecords = response.count;
          // this.totalPages = response.totalPages;
          // this.currentPageNo = response.currentPage;

          this.loading = false;
          // this.updateCSSVariables(this.overallHealthScore);
        },
        (error: any) => {
          this.loading = false;
          this.commonService.toast(error.error.message, "Error");
        }
      );
  }
  updateCSSVariables(data: any) {
    const progressBar = document.querySelector(
      'div[role="progressbar"]'
    ) as HTMLElement;
    if (progressBar) {
      progressBar.style.setProperty("--value", `${data}`);
    }
  }
  getTargetValue(arr: any, fieldKey: any) {
    let temp = arr.filter(({ key, value }) => {
      if (key === this.durationFilter) {
        if (
          fieldKey == "Sales" ||
          fieldKey == "Employee satisfaction" ||
          fieldKey == "Revenue" ||
          fieldKey == "Profit"
        )
          return parseFloat(value).toFixed(2);
        else return value;
      }
    });
    if (
      fieldKey == "Sales" ||
      fieldKey == "Employee satisfaction" ||
      fieldKey == "Revenue" ||
      fieldKey == "Profit"
    )
      return parseFloat(temp[0].value).toFixed(2);
    else return temp[0].value;
  }

  getTrendIcon(current: number, previous: number, key: any): string {
    if (key == "Incomplete jobs" || key == "Complaints")
      return current < previous
        ? "fas fa-arrow-alt-circle-up text-green"
        : "fas fa-arrow-alt-circle-down text-red";
    else
      return current > previous
        ? "fas fa-arrow-alt-circle-up text-green"
        : "fas fa-arrow-alt-circle-down text-red";
  }

  handleSelectSearch(e: any, v: any) {
    this.loading = true;
    this.updateCSSVariables(0);
    if (e === "week") {
      this.durationFilter = "week";
      this.currentStartDate = moment().startOf("isoWeek").format("YYYY-MM-DD");
      this.previousStateDate = moment(this.currentStartDate)
        .subtract(1, "weeks")
        .format("YYYY-MM-DD");
      this.previusEndDate = moment(this.previousStateDate)
        .endOf("isoWeek")
        .format("YYYY-MM-DD");

      this.getContacts();
    } else if (e === "month") {
      this.durationFilter = "month";
      this.currentStartDate = moment().startOf("month").format("YYYY-MM-DD");
      this.previousStateDate = moment(this.currentStartDate)
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD");
      this.previusEndDate = moment(this.previousStateDate)
        .endOf("month")
        .format("YYYY-MM-DD");
      this.getContacts();
    } else if (e === "3month") {
      this.durationFilter = "3month";
      let currentDate = moment();
      let currentQuarter = Math.ceil(currentDate.month() / 3);
      this.currentStartDate = moment()
        .quarter(currentQuarter)
        .startOf("quarter")
        .format("YYYY-MM-DD"); // got write date
      this.previousStateDate = moment(this.currentStartDate)
        .subtract(3, "month")
        .format("YYYY-MM-DD");
      this.previusEndDate = moment(this.currentStartDate)
        .subtract(1, "day")
        .format("YYYY-MM-DD");
      this.getContacts();
    } else if (e === "6month") {
      this.durationFilter = "6month";
      let currentDate = moment();
      let currentSixMonthPeriod = Math.ceil(currentDate.month() / 6);
      // let currentSixMonthPeriod = 2;
      if (currentSixMonthPeriod == 1) {
        let previousYear = moment()
          .subtract(1, "year")
          .startOf("year")
          .format("YYYY-MM-DD");
        this.previousStateDate = moment(previousYear)
          .add(6, "month")
          .startOf("month")
          .format("YYYY-MM-DD");
        this.previusEndDate = moment(this.previousStateDate)
          .add(6, "month")
          .endOf("month")
          .format("YYYY-MM-DD");
        this.currentStartDate = moment(this.previusEndDate)
          .add(1, "day")
          .format("YYYY-MM-DD");
      } else {
        this.previousStateDate = moment().startOf("year").format("YYYY-MM-DD");
        this.previusEndDate = moment(this.previousStateDate)
          .add(5, "month")
          .endOf("month")
          .format("YYYY-MM-DD");
        this.currentStartDate = moment(this.previusEndDate)
          .add(1, "day")
          .format("YYYY-MM-DD");
      }
      this.getContacts();
    } else {
      this.durationFilter = "one_year";
      this.currentStartDate = moment().startOf("year").format("YYYY-MM-DD");
      this.previousStateDate = moment(this.currentStartDate)
        .subtract(1, "year")
        .startOf("month")
        .format("YYYY-MM-DD");
      this.previusEndDate = moment(this.currentStartDate)
        .subtract(1, "year")
        .endOf("year")
        .format("YYYY-MM-DD");
    }
    this.getContacts();
  }

  handleInventoryNotesModel(inventoryId: string) {
    const initialState = {
      inventoryId,
      noteType: "ad-control",
    };
    this.modalService.show(InventoryNotesComponent, {
      initialState,
      class: "modal-lg",
    });
    this.modalService.onHide.subscribe(() => {
      this.getContactFormDashboardData();
    });
  }

  handleLinksModal(links: any, inventoryId: any) {
    const initialState = {
      items: links,
      inventoryId,
      link: "ad-control",
    };
    this.modalService.show(InventoryLinksComponent, {
      initialState,
      class: "modal-lg",
    });
    this.modalService.onHide.subscribe(() => {
      this.loader = true;
      this.getContactFormDashboardData();
    });
  }
  handleDocUpload(companyOverviewId: string, images: string[]) {
    const initialState = {
      companyOverviewId,
      images,
      type: "ad-control",
    };
    this.modalService.show(InventoryDocUploaderComponent, {
      initialState,
      class: "modal-lg",
    });
    this.modalService.onHide.subscribe(() => {
      this.getContactFormDashboardData();
    });
  }

  handleEditTarget() {
    const formData = { target: this.formData.value.target };
    this.api.editTarget(formData, this.SelectedId).subscribe(
      (result: any) => {
        this.commonService.toast(result.message, "Success");
        this.SelectedId = null;
        this.getContactFormDashboardData();
        this.formData.reset();
        this.closebutton.nativeElement.click();
      },
      (error: any) => {
        this.commonService.toast(error.error.message, "Error");
      }
    );
  }
  handleEditProfitTarget() {
    const formData = {
      operatingExpenses: this.profitformData.value.operatingExpenses,
    };
    this.api.addOperatingExpense(formData, this.SelectedId).subscribe(
      (result: any) => {
        this.commonService.toast(result.message, "Success");
        this.SelectedId = null;
        this.getContactFormDashboardData();
        this.profitformData.reset();
        this.closeProfit.nativeElement.click();
      },
      (error: any) => {
        this.commonService.toast(error.error.message, "Error");
      }
    );
  }
  setSelectedTicketId(id: any, key: any, array: any, isOp: boolean) {
    this.SelectedId = id;
    this.isProfit = isOp;
    for (const item of array) {
      const targetItem = this.initialEditData.target.find(
        (obj) => obj.key === item.key
      );
      if (targetItem) {
        targetItem.value = item.value;
      }
    }
    console.log(this.isProfit, "this.isProfit");
    this.isProfit
      ? (this.profitformData = this._fb.group({
          operatingExpenses: this._fb.array(
            this.initialEditData.target.map((entry) =>
              this.createEntryGroup(entry)
            )
          ),
        }))
      : (this.formData = this._fb.group({
          target: this._fb.array(
            this.initialEditData.target.map((entry) =>
              this.createEntryGroup(entry)
            )
          ),
        }));
  }

  getTargetValueProfit(data: any) {
    let temp = data.filter(({ key, value }) => {
      if (key === this.durationFilter) {
        return parseFloat(value).toFixed(2);
      }
    });
    return parseFloat(temp[0].value).toFixed(2);
  }

  closeModal() {
    const modal: any = this.confirmdeleteModal.nativeElement;
  }

  getRsSing(data: any) {
    if (["Revenue", "Profit", "Sales"].includes(data)) return "$";
    else "";
  }
  ngOnDestroy() {
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
    if (this.modalRef) {
      this.modalRef.hide();
      this.modalRef = null;
    }
  }
  DeleteIconComponent() {
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
    if (this.modalRef) {
      this.modalRef.hide();
      this.modalRef = null;
    }
  }
}
