import { Component, OnInit, TemplateRef } from "@angular/core";
import { InspectionService } from "./../inspection.service";
import { CommonService } from "../../../services/common.service";
import { BsModalService, BsModalRef } from "ngx-bootstrap";
import { UserModalComponentComponent } from "./../user-modal-component/user-modal-component.component";
import { ExcelService } from "./../../../services/excel.service";
import { environment } from "../../../../environments/environment";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { Subject } from "rxjs";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-inspection-list-rejected",
  templateUrl: "./inspection-list-rejected.component.html",
  styleUrls: ["./inspection-list-rejected.component.sass"],
})
export class InspectionListRejectedComponent implements OnInit {
  loader = true;
  items: any;
  listData: any;
  status: any;
  reason: any;
  email;
  email1;
  p = 1;
  nodata = false;
  searchText = "";
  selecttype;
  arr_temp = [];
  arr_temp_all = [];
  modalRef: BsModalRef;
  check: boolean;
  listtype_both: any;
  listtype_Accepted: any;
  listtype_Rejected: any;
  obj: any;
  query: any = {
    page: 1,
    limit: environment.defaultPageSize,
    userType: 1,
  };
  recordsPerPage: number;
  totalRecords = 0;
  excel = [];
  APIEndpoint = "";
  constructor(
    private inspection: InspectionService,
    private commonService: CommonService,
    private modalService: BsModalService,
    private excelService: ExcelService,
    private http: HttpClient
  ) {
    this.recordsPerPage = environment.defaultPageSize;
    this.APIEndpoint = environment.APIEndpoint;

  }
  private searchTerms = new Subject<string>();

  ngOnInit() {
    this.getInspectionListByStatus("Rejected");
    this.status = [
      "Open",
      "Pending",
      "Qualified",
      "Accepted",
      "Rebook",
      "Rural",
      "Rejected",
      "Confirmed",
      // "Deleted",
    ];
    this.reason = ["Wrong service", "Wrong area", "No Response"];
    this.arr_temp = [];
    this.searchTerms
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((value) => {
        this.query.search = value;
        this.pageChanged({ page: 1 });
      });
  }
  openModal(template: TemplateRef<any>) {
    console.log("inside open modal");
    // this.selectedItem = item;
    this.modalRef = this.modalService.show(template, { class: "modal-sm" });
  }
  confirm() {
    console.log("inside confirm modal");

    this.Deletefnc();
    this.modalRef.hide();
  }
  decline() {
    console.log("inside decline modal");

    // this.message = 'Declined!';
    this.modalRef.hide();
  }
  getValue(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.searchTerms.next(inputElement.value);
    // return (event.target as HTMLInputElement).value;
  }
  // getInspectionListByStatus(obj) {
  //   this.inspection.getinspectionlistbystatusV2(obj, this.query).subscribe(
  //     (result: any) => {
  //       console.log("result------------->>>", result.data.data);

  //       const items = result.data.data;
  //       this.items = items;
  //       this.totalRecords = result.data.totalCount;
  //       this.loader = false;
  //       this.excel = [];
  //       let curVal = result.data;
  //       if (curVal.length > 0) {
  //         curVal.forEach((row) => {
  //           //delete objNew['_id'];
  //           console.log(row);
  //           this.excel.push(row);
  //         });
  //       }

  //       this.arr_temp_all = this.items.map((result) => result._id);
  //       //  console.log(this.arr_temp_all);
  //       if (obj == "Accepted") {
  //         this.listtype_both = false;
  //         this.listtype_Accepted = true;
  //         this.listtype_Rejected = false;
  //       } else if (obj == "Rejected") {
  //         this.listtype_both = false;
  //         this.listtype_Accepted = false;
  //         this.listtype_Rejected = true;
  //         console.log("this.listtype_Rejected-------", this.listtype_Rejected);
  //       }
  //     },
  //     (error: any) => {
  //       this.loader = false;
  //       this.nodata = true;
  //       // this.commonService.toast(error.error.message, 'Error');
  //     }
  //   );
  // }

  getInspectionListByStatus(obj) {
    this.inspection.getinspectionlistbystatusV2(obj, this.query).subscribe(
      (result: any) => {
        console.log("Full result: ", result); // Log the entire result

        // Check if result and result.data are defined
        if (result && result.data) {
          console.log(result.data, "==>>");
          // this.items = Array.isArray(result.data.data) ? result.data.data : [];
          this.items = result.data.data;
          console.log(this.items);
          this.totalRecords = result.data.totalCount || 0; // Default to 0 if undefined
        } else {
          this.items = []; // Reset items if data is not valid
          this.totalRecords = 0; // Reset totalRecords
        }

        this.loader = false;
        this.excel = [];

        if (this.items.length > 0) {
          this.items.forEach((row) => {
            console.log(row);
            this.excel.push(row);
          });
        }

        this.arr_temp_all = this.items.map((result) => result._id); // Should not throw an error now

        // Update list types based on the status
        this.listtype_both = false;
        this.listtype_Accepted = obj === "Accepted";
        this.listtype_Rejected = obj === "Rejected";
      },
      (error: any) => {
        this.loader = false;
        this.nodata = true;
        console.error("Error fetching inspection list:", error);
      }
    );
  }

  getinspectionlist() {
    this.query.search = this.searchText;
    this.inspection
      .getinspectionlistbystatusV2("Rejected", this.query)
      .subscribe(
        (result: any) => {
          const items = result.data.data;
          this.items = items;
          this.totalRecords = result.data.totalCount;
          // this.excel = [];
          // //this.excel.push()
          // this.inspection.getInspectionListbyStatusForCsv("Rejected").subscribe(
          //   (result1: any) => {
          
          //     //this.excel.push('Image', 'Inspection Mail Status', 'Confirmation Mail Status', '_id', 'Issue Type', 'Template Name', 'Descriptive Text', 'Name', 'Email', 'Address', 'City', 'State', 'Postcode', 'Phonenumber', 'Status', 'Reason', 'Date of enquiry', '_v','Inspection date','Inspection time', 'Confirmation date', 'Confirmation time', )
          // let curVal = result1.data.data;
          // if (curVal.length > 0) {
          //   curVal.forEach((row) => {
          //     //delete objNew['_id'];
          //     console.log(row);
          //     this.excel.push(row);
          //   });
          // }})
          this.arr_temp_all = this.items.map((result) => result._id);
          // console.log(this.arr_temp_all);
          this.loader = false;
          this.nodata = false;
          this.listtype_both = true;
          this.listtype_Accepted = false;
          this.listtype_Rejected = false;
        },
        (error: any) => {
          this.loader = false;
          this.nodata = true;
          // this.commonService.toast(error.error.message, 'Error');
        }
      );
  }

  pageChanged(event: any): void {
    this.p = event.page;
    this.query.page = this.p;
    this.getinspectionlist();
  }

  copydata(index) {
    var doc = "";
    var table = document.getElementById("myTable") as HTMLTableElement;
    //var row,header,cell1, cell2;
    for (var i = 0; i < 11; i++) {
      //var date = table.rows[index+1].cells[i].innerText
      //doc.push(table.rows[index + 1].cells[i].innerText);
      //console.log(table.rows[index+1].cells[i].innerText);
      doc = doc + table.rows[index + 1].cells[i].innerText + "\t";
    }
    // console.log(doc);
    // doc.splice(10, 6);
    // doc.splice(0, 1);
    // //doc.join('\r\n');
    // doc.join("\n")
    // console.log(doc)
    //var str = doc.toString();
    //str.replace(",", "<br />");
    //this.copyStringToClipboard(table.rows[index+1].innerText)
    this.copyStringToClipboard(doc);
  }
  copyStringToClipboard(str) {
    // Create new element
    var el = document.createElement("textarea");
    // Set value (string to be copied)
    el.value = str;
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    //el.style = {position: 'absolute', left: '-9999px'};

    document.body.appendChild(el);
    // Select text inside element
    el.select();
    // Copy text to clipboard
    document.execCommand("copy");
    // Remove temporary element
    document.body.removeChild(el);
  }

  Deletefnc() {
    // this.obj = {
    //obj : this.arr_temp
    //}
    if (this.arr_temp.length == 0) {
      this.commonService.toast("Please select an enquiry to delete", "Error");
    } else {
      this.inspection.deleteinspectionlist(this.arr_temp).subscribe(
        (result: any) => {
          this.loader = true;
          this.pagecall();
          this.loader = false;
          this.commonService.toast("Deletion successful", "Success");
        },
        (error: any) => {
          this.loader = false;
          //this.nodata = true;
          // this.commonService.toast(error.error.message, 'Error');
        }
      );
    }
  }

  // exportAsXLSX(): void {
  //   this.excelService.exportAsExcelFile(this.excel, "User");
  // }
  exportAsXLSX(): void {
    const url = this.APIEndpoint + '/' + 'users/genereate-csv/Rejected'; // URL to your CSV file endpoint
    
    // Open the link in a new tab
    const newTab = window.open(url, '_blank');
  
    if (newTab) {
      // Wait for the new tab to load and trigger the download
      newTab.onload = () => {
        // Set the download behavior for the new tab by modifying its headers on the server side
        // Ensure that your server is sending the correct headers like Content-Disposition for file download
        newTab.location.href = url;  // This triggers the download if the server is set up correctly
      };
    } else {
      console.error('Could not open new tab');
    }
  }
  pagecall() {
    if (this.listtype_both == true) {
      this.getinspectionlist();
    } else if (this.listtype_Accepted == true) {
      this.getInspectionListByStatus("Accepted");
    } else if (this.listtype_Rejected == true) {
      this.getInspectionListByStatus("Rejected");
    }
  }
  userModal(userid) {
    //console.log(userid);
    const initialState = {
      userid: userid,
    };
    this.modalService.show(UserModalComponentComponent, {
      initialState,
      class: "modal-lg",
    });
    this.modalService.onHide.subscribe(() => {
      this.pagecall();
    });
  }
  sendeinspectionmail(emailid, date, time, _id) {
    let obj1 = {
      email: emailid,
      date: date,
      time: time,
      _id: _id,
    };
    if (date == "" || date == undefined || time == "" || time == undefined) {
      this.commonService.toast("Please select the date and time", "Error");
    } else {
      this.inspection.sendeinspectionmail(obj1).subscribe(
        (result: any) => {
          console.log("Inspection email sent successfully.=====");
          this.commonService.toast(
            `Inspection email sent successfully.`,
            "Success"
          );
          this.pagecall();
        },
        (error) => {
          this.commonService.toast(error.error.message, "Error");
        }
      );
    }
  }
  sendconfirmationemail(emailid, date, time, _id) {
    let obj2 = {
      email: emailid,
      date: date,
      time: time,
      _id: _id,
    };
    if (date == "" || date == undefined || time == "" || time == undefined) {
      this.commonService.toast("Please select the date and time", "Error");
    } else {
      this.inspection.sendconfirmationemail(obj2).subscribe(
        (result: any) => {
          console.log("Confirmation email  sent successfully=====");

          this.commonService.toast(
            `Confirmation email  sent successfully.`,
            "Success"
          );
          this.pagecall();
        },
        (error) => {
          this.commonService.toast(error.error.message, "Error");
        }
      );
    }
  }
  // changeStatus(_id, event) {
  //   const status = event.target.value;
  //   this.inspection.updateStatus({ _id, Status: status }).subscribe(
  //     (result: any) => {
  //       this.commonService.toast(`Status updated successfully.`, "Success");
  //       this.pagecall();
  //     },
  //     (error) => {
  //       this.commonService.toast(error.error.message, "Error");
  //     }
  //   );
  // }
  changeStatus(_id, event) {
    const status = event.target.value;
    console.log("changeStatus status", _id, status);

    // If the status is 'Deleted', we want to delete the enquiry
    if (status === "Deleted") {
      console.log("Enquiry will be deleted now.");

      // Call the delete function if the status is 'Deleted'
      this.inspection.deleteinspectionlist([_id]).subscribe(
        (result: any) => {
          console.log("Enquiry deleted result", result);
          this.loader = true;
          this.pagecall(); // Refresh the list after deletion
          this.loader = false;
          // this.commonService.toast("Enquiry deleted successfully", "Success");
        },
        (error: any) => {
          this.loader = false;
          this.commonService.toast(error.error.message, "Error");
        }
      );
    }

    // Now update the status of the enquiry
    this.inspection.updateStatus({ _id, Status: status }).subscribe(
      (result: any) => {
        console.log("Status update result", result);
        this.commonService.toast("Status updated successfully", "Success");
        this.pagecall(); // Refresh the list with the updated status
      },
      (error) => {
        this.commonService.toast(error.error.message, "Error");
      }
    );
  }

  // updated to small letter to camel case like changereason to changeReason
  changeReason(_id, event, email) {
    const reason = event.target.value;

    console.log("reason----------------", reason);
    if (this.status == "") {
      this.commonService.toast(
        "Please select the status before selecting the reason",
        "Error"
      );
      this.pagecall();
    } else if (this.status == "Accepted") {
      this.commonService.toast("The enquiry is in accepted state", "Error");
      this.pagecall();
    } else {
      this.inspection.updatereason({ _id, Reason: reason, email }).subscribe(
        (result: any) => {
          console.log("Reason updated successfully", email, event);
          this.commonService.toast(`Reason updated successfully.`, "Success");
          this.pagecall();
        },
        (error) => {
          this.commonService.toast(error.error.message, "Error");
        }
      );
    }
  }
  deleteselection(_id, check) {
    if (check.target.checked == true) {
      this.arr_temp.push(_id);
      //console.log(this.arr_temp);
    } else if (check.target.checked == false) {
      for (let j = 0; j < this.arr_temp.length; j++) {
        if (this.arr_temp[j] === _id) {
          this.arr_temp.splice(j, 1);
        }
      }
      // console.log(this.arr_temp);
    }
  }
  deleteall(event) {
    const checked = event.target.checked;
    this.items.forEach((item) => (item.selected = checked));
    if (checked == true) {
      this.arr_temp = this.arr_temp_all;
    } else if (checked == false) this.arr_temp = [];
    // console.log(this.arr_temp);
  }
  listchange(event) {
    const listval = event.target.value;
    if (listval == "Both") {
      this.getinspectionlist();
    } else if (listval == "Accepted") {
      this.getInspectionListByStatus("Accepted");
    } else if (listval == "Rejected") {
      this.getInspectionListByStatus("Rejected");
    }
  }
  selectinspectiondate(_id, event) {
    const bdate = event.target.value;
    this.inspection.selectinspectiondate({ _id, idate: bdate }).subscribe(
      (result: any) => {
        this.commonService.toast(
          `Inspection date updated successfully.`,
          "Success"
        );
        this.pagecall();
      },
      (error) => {
        this.commonService.toast(error.error.message, "Error");
      }
    );
  }
  selectinspectiondate1(_id, event) {
    const bdate = event.target.value;
    this.inspection.selectinspectiondate1({ _id, cdate: bdate }).subscribe(
      (result: any) => {
        this.commonService.toast(
          `Confirmed date updated successfully.`,
          "Success"
        );
        this.pagecall();
      },
      (error) => {
        this.commonService.toast(error.error.message, "Error");
      }
    );
  }

  timeset(_id) {
    //const bdate = .target.value;
    //var bdate = document.getElementById("time1").value;
    var bdate = (<HTMLInputElement>document.getElementById("time1")).value;
    this.inspection.selectinspectiontime1({ _id, itime: bdate }).subscribe(
      (result: any) => {
        this.commonService.toast(
          `Inspection time updated successfully.`,
          "Success"
        );
        this.pagecall();
      },
      (error) => {
        this.commonService.toast(error.error.message, "Error");
      }
    );
  }
  timeset1(_id) {
    //const bdate = .target.value;
    //var bdate = document.getElementById("time1").value;
    var bdate = (<HTMLInputElement>document.getElementById("time2")).value;
    this.inspection.selectinspectiontime2({ _id, ctime: bdate }).subscribe(
      (result: any) => {
        this.commonService.toast(
          `Inspection time updated successfully.`,
          "Success"
        );
        this.pagecall();
      },
      (error) => {
        this.commonService.toast(error.error.message, "Error");
      }
    );
  }
}
