import { Component, OnInit, TemplateRef } from "@angular/core";
import { InspectionService } from "./../inspection.service";
import { CommonService } from "../../../services/common.service";
import { BsModalService, BsModalRef } from "ngx-bootstrap";
import { UserModalComponentComponent } from "./../user-modal-component/user-modal-component.component";
import { ExcelService } from "./../../../services/excel.service";
import { environment } from "../../../../environments/environment";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-inspection-qualified",
  templateUrl: "./inspection-qualified.component.html",
  styleUrls: ["./inspection-qualified.component.sass"],
})
export class InspectionQualifiedComponent implements OnInit {
  loader = true;
  items: any;
  items1: any;
  listData: any;
  status1: any;
  reason: any;
  email;
  email1;
  p = 1;
  nodata = false;
  searchText = "";
  selecttype;
  arr_temp = [];
  arr_temp_all = [];
  modalRef: BsModalRef;
  check: boolean;
  listtype_both: any;
  listtype_Accepted: any;
  listtype_Rejected: any;
  obj: any;
  excel = [];
  td;
  query: any = {
    page: 1,
    limit: environment.defaultPageSize,
    userType: 1,
  };
  recordsPerPage: number;
  totalRecords = 0;
APIEndpoint = "";
  constructor(
    private inspection: InspectionService,
    private commonService: CommonService,
    private modalService: BsModalService,
    private excelService: ExcelService,
    private http: HttpClient
  ) {
    this.recordsPerPage = environment.defaultPageSize;
    this.APIEndpoint = environment.APIEndpoint;

  }
  private searchTerms = new Subject<string>();
  ngOnInit() {
    this.getinspectionlist();
    this.status1 = [
      "Open",
      "Pending",
      "Qualified",
      "Accepted",
      "Rejected",
      "Confirmed",
      // "Deleted",
    ];
    this.reason = ["Wrong service", "Wrong area"];
    this.arr_temp = [];
    this.searchTerms
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((value) => {
        this.pageChanged({ page: 1 });
        this.query.search = value;
        this.getinspectionlist();
      });
  }
  openModal(template: TemplateRef<any>) {
    console.log("inside open modal");
    // this.selectedItem = item;
    this.modalRef = this.modalService.show(template, { class: "modal-sm" });
  }
  confirm() {
    console.log("inside confirm modal");

    this.Deletefnc();
    this.modalRef.hide();
  }
  decline() {
    console.log("inside decline modal");

    // this.message = 'Declined!';
    this.modalRef.hide();
  }
  getValue(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.searchTerms.next(inputElement.value);
    // return (event.target as HTMLInputElement).value;
  }

  getinspectionlist() {
    this.query.search = this.searchText;
    this.inspection
      .getinspectionlistbystatusV2("Qualified", this.query)
      .subscribe(
        (result: any) => {
          const items = result.data.data;
          console.log("qualified result", this.items);
          this.items = items;
          this.items1 = items;
          this.totalRecords = result.data.totalCount;
          // this.excel = [];
          // //this.excel.push()
          // this.inspection.getInspectionListbyStatusForCsv("Qualified").subscribe(
          //   (result1: any) => {
          
          //     //this.excel.push('Image', 'Inspection Mail Status', 'Confirmation Mail Status', '_id', 'Issue Type', 'Template Name', 'Descriptive Text', 'Name', 'Email', 'Address', 'City', 'State', 'Postcode', 'Phonenumber', 'Status', 'Reason', 'Date of enquiry', '_v','Inspection date','Inspection time', 'Confirmation date', 'Confirmation time', )
          // let curVal = result1.data.data;
          // if (curVal.length > 0) {
          //   curVal.forEach((row) => {
          //     //delete objNew['_id'];
          //     console.log(row);
          //     this.excel.push(row);
          //   });
          // }})
          this.arr_temp_all = this.items.map((result) => result._id);
          // console.log(this.arr_temp_all);
          this.loader = false;
          this.nodata = false;
          this.listtype_both = true;
          this.listtype_Accepted = false;
          this.listtype_Rejected = false;
          this.nodata = false;
        },
        (error: any) => {
          this.loader = false;
          this.nodata = true;
          // this.commonService.toast(error.error.message, 'Error');
        }
      );
  }

  pageChanged(event: any): void {
    this.p = event.page;
    this.query.page = this.p;
    this.getinspectionlist();
  }

  deleteselection(_id, check) {
    if (check.target.checked == true) {
      this.arr_temp.push(_id);
    } else if (check.target.checked == false) {
      for (let j = 0; j < this.arr_temp.length; j++) {
        if (this.arr_temp[j] === _id) {
          this.arr_temp.splice(j, 1);
        }
      }
      // console.log(this.arr_temp);
    }
  }

  // changeStatus(_id, event) {
  //   const status = event.target.value;
  //   this.inspection.updateStatus({ _id, Status: status }).subscribe(
  //     (result: any) => {
  //       this.commonService.toast(`Status updated successfully.`, "Success");
  //       this.getinspectionlist();
  //     },
  //     (error) => {
  //       this.commonService.toast(error.error.message, "Error");
  //     }
  //   );
  // }
  changeStatus(_id, event) {
    const status = event.target.value;
    console.log("changeStatus status", _id, status);

    // If the status is 'Deleted', we want to delete the enquiry
    if (status === "Deleted") {
      console.log("Enquiry will be deleted now.");

      // Call the delete function if the status is 'Deleted'
      this.inspection.deleteinspectionlist([_id]).subscribe(
        (result: any) => {
          console.log("Enquiry deleted result", result);
          this.loader = true;
          this.pagecall(); // Refresh the list after deletion
          this.loader = false;
          // this.commonService.toast("Enquiry deleted successfully", "Success");
        },
        (error: any) => {
          this.loader = false;
          this.commonService.toast(error.error.message, "Error");
        }
      );
    }
    this.inspection.updateStatus({ _id, Status: status }).subscribe(
      (result: any) => {
        console.log("Status update result", result);
        this.commonService.toast("Status updated successfully", "Success");
        this.pagecall(); // Refresh the list with the updated status
      },
      (error) => {
        this.commonService.toast(error.error.message, "Error");
      }
    );
  }
  Deletefnc() {
    // this.obj = {
    //obj : this.arr_temp
    //}
    if (this.arr_temp.length == 0) {
      this.commonService.toast("Please select an enquiry to delete", "Error");
    } else {
      this.inspection.deleteinspectionlist(this.arr_temp).subscribe(
        (result: any) => {
          this.loader = true;
          this.getinspectionlist();
          this.loader = false;
          // this.commonService.toast("Deletion successful", "Success");
        },
        (error: any) => {
          this.loader = false;
          //this.nodata = true;
          // this.commonService.toast(error.error.message, 'Error');
        }
      );
    }
  }

  // exportAsXLSX(): void {
  //   this.excelService.exportAsExcelFile(this.excel, "User");
  // }

  exportAsXLSX(): void {
    const url = this.APIEndpoint + '/' + 'users/genereate-csv/Qualified'; // URL to your CSV file endpoint
    
    // Open the link in a new tab
    const newTab = window.open(url, '_blank');
  
    if (newTab) {
      // Wait for the new tab to load and trigger the download
      newTab.onload = () => {
        // Set the download behavior for the new tab by modifying its headers on the server side
        // Ensure that your server is sending the correct headers like Content-Disposition for file download
        newTab.location.href = url;  // This triggers the download if the server is set up correctly
      };
    } else {
      console.error('Could not open new tab');
    }
  }
  copydata(index) {
    var doc = "";
    var table = document.getElementById("myTable") as HTMLTableElement;
    for (var i = 0; i < 11; i++) {
      doc = doc + table.rows[index + 1].cells[i].innerText + "\t";
    }
    this.copyStringToClipboard(doc);
  }
  copyStringToClipboard(str) {
    // Create new element
    var el = document.createElement("textarea");
    // Set value (string to be copied)
    el.value = str;
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    //el.style = {position: 'absolute', left: '-9999px'};

    document.body.appendChild(el);
    // Select text inside element
    el.select();
    // Copy text to clipboard
    document.execCommand("copy");
    // Remove temporary element
    document.body.removeChild(el);
  }

  pagecall() {
    //if (this.listtype_both == true) {
    this.getinspectionlist();
    //} else if (this.listtype_Accepted == true) {
    //this.getInspectionListByStatus("Accepted");
    //} else if (this.listtype_Rejected == true) {
    //this.getInspectionListByStatus("Rejected");
    //}
  }
  userModal(userid) {
    console.log(userid);
    const initialState = {
      userid: userid,
    };
    this.modalService.show(UserModalComponentComponent, {
      initialState,
      class: "modal-lg",
    });
    this.modalService.onHide.subscribe(() => {
      this.getinspectionlist();
    });
  }
}
