import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { InspectionComponent } from './inspection/inspection.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { InspectionListComponent } from './inspection-list/inspection-list.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { UserModalComponentComponent } from './user-modal-component/user-modal-component.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ThankyouModalComponent } from './thankyou-modal/thankyou-modal.component';
import { AutocompleteComponent } from './google-places/google-places.component';
import { InspectionListAcceptedComponent } from './inspection-list-accepted/inspection-list-accepted.component';
import { InspectionListRejectedComponent } from './inspection-list-rejected/inspection-list-rejected.component';
import { InspectionListPendingComponent } from './inspection-list-pending/inspection-list-pending.component';
import { InspectionListConfirmedComponent } from './inspection-list-confirmed/inspection-list-confirmed.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
//import {  MatFormFieldModule, MatInputModule, MatRippleModule } from '@angular/material';
//import { MatButtonModule } from '@angular/material/button';
//import { MatInputModule } from '@angular/material/input';
import { MatInputModule } from '@angular/material/input';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from 'ngx-mat-datetime-picker';
import { EditinspectionComponent } from './editinspection/editinspection.component';
import { InspectionRoutes } from './inspection.routing';
import { InspectionListDumpComponent } from './inspection-list-dump/inspection-list-dump.component';
import { AllinspectionComponent } from './allinspection/allinspection.component';
import { PaginationModule } from 'ngx-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { InspectionQualifiedComponent } from './inspection-qualified/inspection-qualified.component';
import { InspectionQualifiedQLDComponent } from './inspection-qualified-QLD/inspection-qualified-qld.component';
import { InspectionQualifiedVICComponent } from './inspection-qualified-VIC/inspection-qualified-vic.component';
import { InspectionRebookComponent } from './inspection-rebook/inspection-rebook.component';
import { InspectionRuralComponent } from './inspection-rural/inspection-rural.component';

//import {LayoutsModule} from '../../layouts/layouts.module'




@NgModule({
  declarations: [InspectionComponent, InspectionListComponent, UserModalComponentComponent, ThankyouModalComponent, AutocompleteComponent, InspectionListAcceptedComponent, InspectionListRejectedComponent, InspectionListPendingComponent, InspectionListConfirmedComponent, EditinspectionComponent, InspectionListDumpComponent, AllinspectionComponent, InspectionQualifiedComponent, InspectionQualifiedQLDComponent, InspectionQualifiedVICComponent, InspectionRebookComponent, InspectionRuralComponent],
  imports: [
    PaginationModule.forRoot(),
    NgxPaginationModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule, NgxPaginationModule,
    Ng2SearchPipeModule,
    PipesModule,
    NgSelectModule,
    //LayoutsModule,
    RouterModule.forChild(InspectionRoutes),
    //MatInputModule,
    MatInputModule,
    NgxMaterialTimepickerModule,
    ModalModule.forRoot()
  ],
  entryComponents: [UserModalComponentComponent, ThankyouModalComponent]
})
export class InspectionModule { }
