import { Component, OnInit } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { MarketingService } from "../marketing.service";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: "app-contact-add-form",
  templateUrl: "./contact-add-form.component.html",
  styleUrls: ["./contact-add-form.component.sass"],
})
export class ContactAddFormComponent implements OnInit {
  editId = true;
  isLoading: Boolean = false;
  addUserForm: FormGroup;
  loader;
  _id = "";
  campaignFormUrl;
  submitted = false;
  selectedFormId: string | null = null; // Store selected form ID
  campaignForm;
  formGroup: FormGroup;
  linkPages: Boolean = true;
  disableSubmit: Boolean = false;
  constructor(
    private router: Router,
    private api: MarketingService,
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private _fb: FormBuilder
  ) {
    this.createForm();
  }
  ngOnInit() {
    if (this.activatedRoute.snapshot.params.id !== undefined) {
      this.editId = false;
      this._id = this.activatedRoute.snapshot.params.id;
      this.loadData(this._id);
    }
  }
  handleClose() {
    // window.location.href=['']
    console.log("hogya bsssssss");
    this.router.navigate(["/Marketing/contact-form"]);
  }
  createLinkPage(): FormGroup {
    return new FormGroup({
      campaignNameOrDescription: new FormControl("", Validators.required),
      url: new FormControl("", [
        Validators.required,
        Validators.pattern("https?://.+"),
      ]), // URL validation
    });
  }
  removeCampaign(index: number) {
    console.log("inside remove-campaign----------------------");
    const control = <FormArray>this.formGroup.controls["CampaignDetails"];
    control.removeAt(index);
  }

  validateNumberInput(event: any) {
    const inputValue = event.target.value;

    // Check if the input is a negative number or contains non-numeric characters
    if (inputValue && (parseFloat(inputValue) < 0 || isNaN(inputValue))) {
      event.target.value = inputValue.replace(/[^0-9]/g, ""); // Remove any non-numeric characters
    }
  }
  onFocus() {
    console.log("on focus");

    const control = this.campaignForm.get("campaignFormUrl");
    if (control && !control.value.startsWith("https://")) {
      control.setValue("https://");
    }
  }
  onInput(event: any) {
    console.log("on input");
    const control = this.campaignForm.get("campaignFormUrl");
    let value = event.target.value;

    // If the value doesn't start with 'https://', add it
    if (value && !value.startsWith("https://")) {
      control.setValue("https://" + value);
    }
  }

  // get single data

  onFormSelect(formId: string) {
    this.selectedFormId = formId;
    console.log("Selected Form ID:", this.selectedFormId);
  }
  prependHttps() {
    const campaignFormUrl = this.formGroup.controls["campaignFormUrl"].value;
    if (campaignFormUrl && !campaignFormUrl.startsWith("https://")) {
      // Prepend 'https://' if not already present
      this.formGroup.controls["campaignFormUrl"].setValue(
        "https://" + campaignFormUrl
      );
    }
  }
  loadData(uId) {
    console.log("Loading data for user ID:", uId);

    this.api.getSingleContactData(uId).subscribe(
      (result: any) => {
        const items = result.data;
        console.log("Data loaded:", items);
        this.editUserData(items);
      },
      (error: any) => {
        this.commonService.toast(error.error.message, "Error");
      }
    );
  }
  // editUserData(data) {
  //   console.log("Editing user data:", data);
  //   // formName: formValue.formName,

  //   this.formGroup.patchValue({
  //     formName: data.formName || "",
  //     formNo: data.formNo || "", // fill formNo with existing data
  //     campaignFormUrl: data.campaignFormUrl || "", // fill form URL with existing data
  //   });

  //   const linkPagesArray = this.formGroup.get("linkPages") as FormArray;
  // }
  // editUserData(data: any) {
  //   console.log("Editing user data:", data);

  //   // Patch the values into the formGroup to populate the form fields
  //   this.formGroup.patchValue({
  //     formName: data.formName || "", // Ensure formName is patched with existing data or default to empty string
  //     formNo: data.formNo || "", // Fill formNo with existing data or default to empty string
  //     campaignFormUrl: data.campaignFormUrl || "", // Fill campaign form URL with existing data or default to empty string
  //     notes: data.notes || "",
  //   });

  // }
  editUserData(data: any) {
    console.log("Editing user data:", data);

    // Patch the basic form values into the formGroup
    this.formGroup.patchValue({
      formName: data.formName || "",
      formNo: data.formNo || "",
      campaignFormUrl: data.campaignFormUrl || "",
      notes: data.notes || "",
    });

    const linkPagesArray = this.formGroup.get("linkPages") as FormArray;

    // Check if data.linkPages exists and if the form array is empty
    if (data.linkPages && Array.isArray(data.linkPages)) {
      // Clear existing link pages only if data.linkPages is different from existing form array length
      if (linkPagesArray.length > 0) {
        // If there are already form groups, only update the existing ones
        data.linkPages.forEach((campaign, index) => {
          if (linkPagesArray.at(index)) {
            // Patch the values of existing form groups
            linkPagesArray.at(index).patchValue({
              campaignNameOrDescription:
                campaign.campaignNameOrDescription || "",
              url: campaign.url || "",
            });
          }
        });

        // If there are fewer linkPages in the data than in the form, remove the extra form groups
        for (let i = data.linkPages.length; i < linkPagesArray.length; i++) {
          linkPagesArray.removeAt(i);
        }
      } else {
        // If the form array is empty, add new form groups
        data.linkPages.forEach((campaign) => {
          linkPagesArray.push(
            this._fb.group({
              campaignNameOrDescription: [
                campaign.campaignNameOrDescription || "",
              ],
              url: [campaign.url || ""],
            })
          );
        });
      }
    }
  }

  updateUser() {
    console.log("inside update user");
    if (this.formGroup.valid) {
      this.isLoading = true;
      const formValue = this.formGroup.value;
      const requestData = {
        formName: formValue.formName,
        formNo: formValue.formNo,
        // campaignFormUrl: formValue.campaignFormUrl,
        // campaignFormUrl: `${formValue.campaignFormUrl}/${formValue.formNo}`,
        campaignFormUrl: `${formValue.campaignFormUrl}`,
        notes: formValue.notes,
        linkPages: formValue.linkPages.map((campaign) => ({
          campaignNameOrDescription: campaign.campaignNameOrDescription,
          url: campaign.url,
          //  url: `${campaign.url}/${formValue.formNo}`,
        })),
      };
      // Make API call for updating the data
      this.api.editContactForm(requestData, this._id).subscribe(
        (result: any) => {
          this.isLoading = false;
          this.commonService.toast(result.message, "Success");
          this.router.navigate(["Marketing/contact-form"]);
        },
        (error: any) => {
          this.isLoading = false;
          this.commonService.toast("Something went wrong", "Error");
        }
      );
    } else {
      this.isLoading = false;
      this.commonService.toast("Please fill all fields", "Error");
    }
  }

  createForm() {
    this.formGroup = new FormGroup({
      formName: new FormControl(""),
      formNo: new FormControl(""),
      campaignFormUrl: new FormControl(""),
      linkPages: new FormArray([
        new FormGroup({
          campaignNameOrDescription: new FormControl(""),
          url: new FormControl(""),
        }),
      ]),
      notes: new FormControl(""),
    });
  }

  addLinkPages() {
    console.log("inside add-linkPages----------------------");

    const control = <FormArray>this.formGroup.controls["linkPages"];
    control.push(
      new FormGroup({
        campaignNameOrDescription: new FormControl(""),
        url: new FormControl(""),
      })
    );
  }
  // addLinkPages() {
  //   // Add a new form group to linkPages array only if there isn't already an empty entry
  //   const linkPagesArray = this.formGroup.get("linkPages") as FormArray;

  //   // Prevent adding the same form group more than once if the array is empty
  //   if (
  //     linkPagesArray.length === 0 ||
  //     !linkPagesArray.controls.some((control: FormGroup) => !control.valid)
  //   ) {
  //     linkPagesArray.push(
  //       this._fb.group({
  //         campaignNameOrDescription: [""],
  //         url: [""],
  //       })
  //     );
  //   }
  // }

  removeLinkPages(index: number) {
    console.log("inside remove-linkPages----------------------");

    const control = <FormArray>this.formGroup.controls["linkPages"];
    control.removeAt(index);
  }

  handleSubmit() {
    if (this.formGroup.valid) {
      this.isLoading = true;
      this.loader = true;

      const formValue = this.formGroup.value;
      console.log(formValue, ">>");

      // console.log(target, "target");
      const requestData = {
        formName: formValue.formName,
        formNo: formValue.formNo,
        // campaignFormUrl: formValue.campaignFormUrl,
        campaignFormUrl: `${formValue.campaignFormUrl}/${formValue.formNo}`,
        notes: formValue.notes,
        linkPages: formValue.linkPages.map((campaign) => ({
          campaignNameOrDescription: campaign.campaignNameOrDescription,
          url: campaign.url,
          //  url: `${campaign.url}/${formValue.formNo}`,
        })),
      };

      console.log(requestData, "<<<<<requestData");

      this.api.addContactFormData(requestData).subscribe(
        (result: any) => {
          console.log("success ", result);
          this.commonService.toast(result.message, "Success");
          this.isLoading = false;
          this.loader = false;
          this.router.navigate(["Marketing/contact-form"]);
        },
        (error: any) => {
          console.log("error in add ad ");
          this.isLoading = false;
          this.loader = false;

          this.commonService.toast("something went wrong", "Error");
        }
      );
    } else {
      console.log("Form is invalid", this.formGroup.value);
      // this.disableSubmit = true;
      this.isLoading = false;
      this.commonService.toast("Please fill all fields", "Error");
    }
  }
}
