import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { CommonService } from "src/app/services/common.service";
import { UserService } from "./../../services/user.service";

@Component({
  selector: "app-otp-sent-modal",
  templateUrl: "./otp-sent-modal.component.html",
  styleUrls: ["./otp-sent-modal.component.sass"],
})
export class OtpSentModalComponent implements OnInit {
  message: string;
  otp: any;
  usersData: any;
  generateOtpResponse: any;
  isOtpSent: Boolean;

  constructor(
    public modalRef: BsModalRef,
    private commonService: CommonService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    console.log("OTP Sent Modal initialized");
  }
  cancelModal() {
    this.modalRef.hide();
  }
  generateOtpOnInit() {
    this.cancelModal();

    console.log("getting generate otp api called ");
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("currentSession");
    console.log(token);
    if (userId) {
      const payload = { _id: JSON.parse(userId) };

      this.userService.generateOtp(payload).subscribe(
        (response) => {
          // this.cancelModal();

          this.usersData = response;
          console.log(
            "response==================>>>>",
            response,
            this.usersData
          );
          if (this.usersData.success) {
            // this.cancelModal();
            this.isOtpSent = true;
            console.log(this.isOtpSent, ">>");
            this.commonService.toast(
              "OTP sent to your mail successfully.",
              "Success"
            );
          } else {
            this.isOtpSent = false;
            this.commonService.toast(this.usersData.message, "Error");
          }
        },
        (error) => {
          this.commonService.toast(error.error.message, "Error");
        }
      );
    }
  }
}
